import React from "react";
import loading_animation from "assets/lottie/loading_animation.json";
import Lottie from "lottie-react";
import { Spinner } from "reactstrap";

const ComingSoon = () => {
  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <h1 style={styles.title}>Coming Soon</h1>
        <p style={styles.description}>
          We are working hard to bring something amazing for you!
        </p>
        <div style={styles.loader}>
          <Spinner color="primary" type="grow">
            Loading...
          </Spinner>
          <Spinner color="success" type="grow">
            Loading...
          </Spinner>
          <Spinner color="warning" type="grow">
            Loading...
          </Spinner>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "65vh",
    backgroundColor: "#f7f7f7",
  },
  innerContainer: {
    textAlign: "center",
    padding: "20px",
  },
  title: {
    fontSize: "2.5rem",
    color: "#333",
    marginBottom: "20px",
  },
  description: {
    fontSize: "1.2rem",
    color: "#666",
    marginBottom: "40px",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dot: {
    width: "15px",
    height: "15px",
    margin: "0 5px",
    borderRadius: "50%",
    backgroundColor: "#3498db",
    animation: "loading 1s infinite",
  },
  "@keyframes loading": {
    "0%": { transform: "scale(1)" },
    "50%": { transform: "scale(1.5)", opacity: 0.7 },
    "100%": { transform: "scale(1)", opacity: 1 },
  },
};

export default ComingSoon;

import PageLoader from "components/Loader/PageLoader";
import { useCreateBillStore } from "hooks/CreateBill";
import { useLoginStore } from "hooks/Login";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import percentage from "assets/img/icons/createBill/percentage3.png";
import rupee from "assets/img/icons/createBill/rupee.png";
import { useReactToPrint } from "react-to-print";
import Invoice from "./Invoice";
import { BsCurrencyRupee } from "react-icons/bs";
import getSymbolFromCurrency from "currency-symbol-map";
import CreateBillRepository from "repository/CreateBillRepository";
import { ADMIN_URL_BILL_DASHBOARD } from "helpers/Paths";
import "assets/styles/CreateBill.css";
import CustomButton from "components/Button/CustomButton";
import Common from "helpers/Common";
import moment from "moment";

const RefundBill = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const currency = new CreateBillRepository().getCurrencyAbbriviation();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [{}, { getBill }, { refundBill }] = useCreateBillStore();

  const [initialQuantities, setInitialQuantities] = useState({});
  const [loader, setLoader] = useState(false);
  const [qty, setQty] = useState();
  const [rate, setRate] = useState();
  const [amount, setAmount] = useState(0);
  const [discount, setDiscount] = useState();
  const [productCostBeforeTax, setProductCostBeforeTax] = useState(0);
  const [SGST, setSGST] = useState();
  const [CGST, setCGST] = useState();
  const [productCost, setProductCost] = useState(0);
  const [rows, setRows] = useState([]);
  const [manualDiscount, setManualDiscount] = useState(null);
  const [manualDiscountAmount, setManualDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [igstAmount, setIgstAmount] = useState(0);
  const [totalBeforeTax, setTotalBeforeTax] = useState(0);
  const [paymentDone, setPaymentDone] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [showInRupees, setShowInRupees] = useState(false);
  const [billId, setBillId] = useState(0);
  const [igstPercentage, setIgstPercentage] = useState(18);
  const [branchDetail, setBranchDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [initialGrandTotal, setInitialGrandTotal] = useState(0);
  const [billData, setBillData] = useState(null);
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);
  const [expiryDays, setExpiryDays] = useState(null);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerData, setCustomerData] = useState({
    customerID: 0,
    customerName: "",
    address: "",
    phoneNumber: "",
    customerEmail: "",
  });
  const componentRef = useRef();

  const fetchBillData = async (billId) => {
    const fetchBillData = await getBill(billId);
    setBillData(fetchBillData);
    setSelectedCustomer(fetchBillData.customerName);
    setCustomerData({
      customerID: fetchBillData.customerId,
      customerName: fetchBillData.customerName,
      address: fetchBillData.customerAddress,
      phoneNumber: fetchBillData.customerContact,
      customerEmail: fetchBillData.customerEmail,
    });
    setRows(fetchBillData.productInfos);
    setManualDiscount(fetchBillData.manualDiscount);
    setTotalAmount(fetchBillData.grandTotal);
    setTotalBeforeTax(fetchBillData.totalBeforeTax);
    setIgstPercentage(fetchBillData.gst);
    setBillId(billId);

    const newInitialQuantities = fetchBillData.productInfos.reduce(
      (acc, row, index) => {
        acc[index] = row.qty - row.refundQty;
        return acc;
      },
      {}
    );

    setInitialQuantities(newInitialQuantities);

    const isPercentage =
      fetchBillData.manualDiscountType === "percentage" ? true : false;
    setShowInRupees(!isPercentage);
    setPaymentDone(fetchBillData.paidStatus);
    setPaymentMethod(fetchBillData.paymentMode);
    setSubTotal(fetchBillData.subTotal);
    setLoader(false);
    setInitialGrandTotal(parseFloat(fetchBillData.grandTotal));
  };

  useEffect(() => {
    // Fetch expiry_days from localStorage
    const companyProfile = JSON.parse(localStorage.getItem("companyProfile"));

    if (companyProfile) {
      const expiryDaysObj = companyProfile.find(
        (item) => item.feature_key === "expiry_days"
      );
      if (expiryDaysObj) {
        setExpiryDays(Number(expiryDaysObj.feature_value)); // Ensure it's a number
      }
    }
  }, []);

  const updateRow = (index, field, value) => {
    setFirstTimeLoad(false);
    const initialQty = initialQuantities[index]; // Make sure initialQuantities is correctly set up
    let newValue = value;
    // If updating quantity, check against initial quantity
    if (field === "qty") {
      newValue = !value ? "" : Math.min(Math.max(0, value), initialQty); // Ensure newValue is between 0 and initialQty

      if (value > initialQty) {
        setAlerts({
          type: "warning",
          message: ` You can not refund more than ${initialQty} quantity`,
        });
      }
    }

    const updatedRows = rows.map((row, idx) => {
      if (idx === index) {
        const updatedRow = { ...row, [field]: newValue };

        // Perform calculations only if this is the row being updated
        updatedRow.amount = updatedRow.qty * updatedRow.rate;
        updatedRow.productCost = updatedRow.amount - updatedRow.discountAmount;
        updatedRow.netAmount =
          updatedRow.productCost +
          (updatedRow.productCost * updatedRow.sgst) / 100 +
          (updatedRow.productCost * updatedRow.cgst) / 100;

        return updatedRow;
      }
      return row;
    });

    setRows(updatedRows);
  };

  useEffect(() => {
    setLoader(true);
    fetchBillData(id);
  }, []);

  useEffect(() => {
    const newAmount = qty * rate;

    if (isNaN(newAmount)) {
      // Check for NaN
      setAmount(0);
      return;
    }

    setAmount(newAmount);

    if (isNaN(discount)) {
      setProductCostBeforeTax(newAmount);
      const totalCostWithTax = Math.max(
        newAmount + (newAmount * SGST) / 100 + (newAmount * CGST) / 100,
        0.0
      );
      setProductCost(parseFloat(totalCostWithTax));
      return;
    }
    const totalCostWithTax = Math.max(
      newAmount -
        discount +
        ((newAmount - discount) * SGST) / 100 +
        ((newAmount - discount) * CGST) / 100,
      0.0
    );

    const newProductCostBeforeTax = Math.max(newAmount - discount, 0.0);
    setProductCostBeforeTax(newProductCostBeforeTax);

    setProductCost(Math.max(totalCostWithTax, 0.0));
  }, [qty, rate, discount]);

  useEffect(() => {
    const total = parseFloat(
      rows.reduce((acc, row) => acc + parseFloat(row.netAmount), 0)
    );
    setSubTotal(Math.max(total, 0.0));

    const manualDiscountAmountCalculation = showInRupees
      ? manualDiscount !== null
        ? parseFloat(manualDiscount)
        : 0.0
      : (total * manualDiscount) / 100;

    const totalWithDiscount =
      total > 0 ? total - manualDiscountAmountCalculation : 0.0; // Apply manual discount

    setManualDiscountAmount(manualDiscountAmountCalculation);

    if (igstPercentage >= 0 && igstPercentage <= 100) {
      const igst = (totalWithDiscount * igstPercentage) / 100; // Calculate IGST
      setIgstAmount(igst); // Set IGST amount

      setTotalBeforeTax(totalWithDiscount);
      setTotalAmount(totalWithDiscount + igst);
    }
  }, [rows, updateRow, manualDiscount, showInRupees, igstPercentage]);

  const handleRefundBill = async () => {
    setLoading(true);
    try {
      const productInfoData = rows.map((item) => {
        return {
          productId: item.productId,
          productName: item.productName,
          qty: item.qty,
          rate: item.rate,
          cgst: item.cgst,
          cgstType: "PERCENTAGE",
          sgst: item.sgst,
          expiryDate: item?.expiryDate || "",
          sgstType: "PERCENTAGE",
          discountAmount: item.discountAmount,
          productDiscountType: "AMOUNT",
          amount: item.amount,
          expiryDate: item?.expiryDate || null,
          productCost: item.productCost,
          netAmount: parseFloat(item.netAmount),
        };
      });

      const params = {
        companyId: Common.getCompanyId(),
        userId: Common.getUserId(),
        companyName: Common.getCompanyName(),
        companyAddress: Common.getCompanyAddress(),
        companyPhone: Common.getCompanyPhone(),
        companyEmail: Common.getCompanyEmail(),
        companyGSTN: Common.getGstIn(),
        footerText: "Disclaimer and Return Policy",
        invoiceFileName: billId + ".pdf",
        invoiceDate: Common.getFormatedDate(
          moment().utc(),
          "DD-MMMM-YYYY hh:mm:ss",
          true
        ),
        editableStatus: 1,
        refundPossible: 1,
        billDescription: "invoice",
        invoiceReferenceNumber: "",
        billId: billId,
        refundBillId: parseInt(id),
        customerId: customerData.customerID,
        customerName: customerData.customerName,
        customerAddress: customerData.address,
        customerContact: customerData.phoneNumber,
        customerEmail: customerData.customerEmail,
        productInfos: productInfoData,
        subTotal: parseFloat(subTotal).toFixed(2),
        manualDiscount: manualDiscount ? manualDiscount : "0.00",
        manualDiscountAmount: manualDiscountAmount
          ? manualDiscountAmount?.toFixed(2)
          : "0.00",
        manualDiscountType: showInRupees ? "RUPEES" : "PERCENTAGE",
        totalBeforeTax: parseFloat(totalBeforeTax).toFixed(2),
        totalTax: igstAmount?.toFixed(2),
        gst: parseFloat(igstPercentage)?.toFixed(2),
        gstType: "PERCENTAGE",
        paidStatus: true,
        paymentMode: paymentMethod,
        grandTotal: parseFloat(totalBeforeTax)?.toFixed(2),
      };

      const result = await refundBill(params);

      // Check if the API returned errorNumber 50000
      if (result.errorNumber === 50000) {
        setLoading(false);
        await setAlerts({
          type: "danger",
          message:
            result.errorMessage ||
            "You cannot refund more quantity than the bill's maximum quantity.",
        });
        return; // Return early if the specific error condition is met
      }

      // Handle success case
      setBillId(result.billId);

      if (result.billId === 0) {
        setLoading(false);
        await setAlerts({ type: "danger", message: "Error Bill Id is 0." });
      } else {
        setLoading(false);
        await setAlerts({
          type: "success",
          message: `Refund Bill Id ${result.billId} generated for the Invoice Bill Id: ${result.refundBillId}`,
        });
        navigate(ADMIN_URL_BILL_DASHBOARD);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      await setAlerts({
        type: "danger",
        message: "Error saving bill. Please try again later.",
      });
    }
  };

  const formatDate = (dateString) => {
    if (dateString !== null) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // +1 because months are 0-indexed.
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    }
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <div className="create-bill-container">
          <div className="mb-3 d-flex align-items-center justify-content-between">
            <h4 className="title m-0">Bills / Recent Bills / Refund Bill</h4>
          </div>

          <Card>
            <div
              className="mb-3 pb-4"
              style={{ borderBottom: "1px solid #E0E0E0" }}
            >
              <Row>
                <Col xs="12" md="12" lg="12">
                  <div className="title-row">
                    <h4>Customer Name</h4>
                  </div>
                </Col>
              </Row>

              <Row className="align-items-center" style={{ rowGap: "10px" }}>
                <Col xs="12" sm="12" md="6" lg="3">
                  {customerData.customerName}
                </Col>
              </Row>
            </div>

            <>
              <div className="title-row">
                <h4>Purchase Details</h4>
              </div>
              <div style={{ overflowX: "auto" }}>
                <table className="purchase-table">
                  <thead>
                    <tr>
                      <th className="product-name-col">Particulars</th>
                      <th className="qty-col">Quantity</th>
                      <th className="expiry-col">Expiry Date</th>
                      <th className="rate-col">Rate</th>
                      <th className="amount-col">Amount</th>
                      <th className="discount-col">Discount</th>
                      <th className="total-price-col">Total</th>
                      <th className="sgst-col">SGST (%)</th>
                      <th className="cgst-col">CGST (%)</th>
                      <th className="net-amount-col">Net Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td className="product-name-col cutomize-form-control">
                          {row.productName}
                        </td>

                        <td className="qty-col cutomize-form-control">
                          <Input
                            className="cutomize-form-control-input custom-number-input"
                            id={`qty-input-${index}`}
                            type="text"
                            value={
                              firstTimeLoad ? row.qty - row.refundQty : row.qty
                            }
                            onChange={(e) => {
                              const newQty = parseFloat(e.target.value);
                              updateRow(
                                index,
                                "qty",
                                isNaN(newQty) ? "" : newQty
                              );
                            }}
                          />
                        </td>

                        <td
                          className="expiry-col cutomize-form-control p-0"
                          style={{
                            fontSize: "15px",
                            color:
                              moment(row?.expiryDate).diff(moment(), "days") <=
                              expiryDays
                                ? "red" // Optional visual indicator for disabled
                                : "green",
                          }}
                        >
                          {Common.getFormatedDate(
                            row?.expiryDate,
                            "DD MMM YYYY",
                            true
                          ) !== "Invalid date"
                            ? Common.getFormatedDate(
                                row?.expiryDate,
                                "DD MMM YYYY",
                                true
                              )
                            : "N/A"}
                        </td>

                        <td className="rate-col cutomize-form-control">
                          {row.rate}
                        </td>

                        <td className="amount-col cutomize-form-control">
                          {row.amount.toFixed(2)}
                        </td>

                        <td className="discount-col cutomize-form-control">
                          {row.discountAmount}
                        </td>

                        <td className="total-price-col cutomize-form-control">
                          {row.productCost.toFixed(2)}
                        </td>

                        <td className="sgst-col cutomize-form-control">
                          {row.sgst || 0}
                        </td>

                        <td className="cgst-col cutomize-form-control">
                          {row.cgst || 0}
                        </td>

                        <td className="net-amount-col cutomize-form-control">
                          {row.netAmount.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>

            <div className="invoice-summary-container">
              <Row className="w-100">
                <Col xs={12} sm={12} md={12} lg={8}>
                  <Row>
                    <Col md={12}>
                      <div className="d-flex align-items-center mb-2">
                        <h4 className="title">Refund Bill</h4>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ rowGap: "10px" }}>
                    <Col sx={12} sm={12} md={4} lg={4}>
                      <div className="invoice-form-control">
                        <label>Payment Mode</label>
                        <Input
                          className="custom-input-control"
                          id="payment"
                          name="payment"
                          type="text"
                          readOnly
                          value={paymentMethod}
                        />
                      </div>
                    </Col>
                    <Col sx={12} sm={12} md={4} lg={4}>
                      <div className="invoice-form-control">
                        <label>
                          Manual Discount ({showInRupees ? "₹" : "%"})
                        </label>
                        <div className="custom-manual-discount-control">
                          <input
                            type="number"
                            min="0"
                            max="100"
                            readOnly
                            className="manual-discount-input"
                            value={manualDiscount}
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              let value = parseFloat(e.target.value);
                              if (isNaN(value)) {
                                value = "";
                              }

                              if (!showInRupees) {
                                if (value > 100) {
                                  value = 100;
                                } else if (value < 0) {
                                  value = 0;
                                }
                              }
                              setManualDiscount(value);
                            }}
                          />
                          <Input
                            id="discount"
                            name="discount"
                            type="select"
                            value={showInRupees}
                            className="type-dropdown"
                            readOnly
                          >
                            <option value={true}>Fixed</option>
                            <option value={false}>Percentage</option>
                          </Input>
                        </div>
                      </div>
                    </Col>
                    {/* <Col sx={12} sm={12} md={4} lg={4}>
                      <div className="invoice-form-control">
                        <label htmlFor="">IGST (%)</label>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            disabled
                            className="manual-igst-minus-btn"
                            onClick={() =>
                              setIgstPercentage(igstPercentage - 1)
                            }
                          >
                            -
                          </button>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            readOnly
                            className="manual-igst-input"
                            value={igstPercentage || 0}
                            style={{ width: "55px" }}
                            onChange={(e) => {
                              setIgstPercentage(e.target.value);
                            }}
                          />
                          <button
                            disabled
                            className="manual-igst-plus-btn"
                            onClick={() =>
                              setIgstPercentage(igstPercentage + 1)
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                  <Row className="my-3" style={{ rowGap: "10px" }}>
                    <Col sm={12} md={6}>
                      <div className="invoice-form-control">
                        <label htmlFor="">Comments</label>
                        <Input
                          id="comment"
                          name="comment"
                          placeholder="Write down.."
                          type="textarea"
                          maxLength={200}
                          rows={1}
                          className="invoice-comment-control"
                        />
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      className="d-flex align-items-end justify-content-start pb-2"
                      style={{ gap: "10px" }}
                    >
                      <input
                        type="checkbox"
                        id="custom-checkbox"
                        className="custom-checkbox"
                        checked={paymentDone}
                        onChange={() => {
                          setPaymentDone((prev) => !prev);
                        }}
                        disabled
                      />
                      <p className="received-payment-label">Received Payment</p>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={4}
                  className="d-flex flex-column justify-content-between"
                >
                  <Row style={{ gap: "15px" }}>
                    {/* <Col md={12} className="invoice-summary-data-row">
                      <p>Total</p>
                      <p style={{ color: "#483EB6" }}>
                        {'(' + currency + ') ' + getSymbolFromCurrency(currency) + ' ' + totalBeforeTax}
                      </p>
                    </Col> */}
                    <Col md={12} className="invoice-summary-data-row">
                      <p>Sub Total</p>
                      <p style={{ color: "#483EB6" }}>{subTotal.toFixed(2)}</p>
                    </Col>
                    <Col md={12} className="invoice-summary-data-row">
                      <p>Manual Discount</p>
                      <p style={{ color: "#FF0000" }}>
                        {"- " + (manualDiscountAmount ?? 0)?.toFixed(2)}
                      </p>
                    </Col>
                    <Col md={12} className="invoice-summary-data-row">
                      <p>Net Payable Amount</p>
                      <p style={{ color: "#58AD6B", fontSize: "15px" }}>
                        {" "}
                        {"(" +
                          (currency ?? "0") +
                          ") " +
                          (getSymbolFromCurrency(currency) ?? "") +
                          " " +
                          (totalBeforeTax ?? 0)?.toFixed(2)}{" "}
                      </p>
                    </Col>
                  </Row>
                  {billData?.refundPossible === 1 && (
                    <>
                      <Row className="mt-2">
                        <Col
                          md={12}
                          className="d-flex justify-content-start align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <CustomButton
                            label={"Refund Bill"}
                            disabled={loading || billData?.refundBillId}
                            onClick={handleRefundBill}
                          />

                          {/* <CustomButton
                            label={loadingPrint ? "Saving..." : "Refund & Print"}
                            disabled={loadingPrint || billData?.refundBillId}
                            onClick={handlePrint}
                          /> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div style={{ overflow: "hidden", height: 0 }}>
                            <Invoice
                              ref={componentRef}
                              rows={rows}
                              subTotal={subTotal}
                              totalBeforeTax={parseFloat(
                                totalBeforeTax
                              ).toFixed(2)}
                              igst={igstAmount.toFixed(2)}
                              total={totalAmount.toFixed(2)}
                              manualDiscount={manualDiscount}
                              manualDiscountAmount={manualDiscountAmount}
                              customerData={customerData}
                              selectedCustomer={selectedCustomer}
                              branchDetail={branchDetail}
                              billID={billId}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col sm={12} md={12}>
                      {billData?.refundPossible === 0 && (
                        <center>
                          <div style={{ color: "red", fontSize: "20px" }}>
                            You are not allowed to perform this operation.
                          </div>
                        </center>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default RefundBill;

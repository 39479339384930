import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  Row,
  Col,
  Input,
  Popover,
  PopoverBody,
} from "reactstrap";
import { useCreateBillStore } from "hooks/CreateBill";
import { AutoComplete } from "primereact/autocomplete";
import "../../assets/styles/CreateBill.css";
import "../../assets/styles/Invoice.css";
import PageLoader from "components/Loader/PageLoader";
import { isEmpty } from "underscore";
import { useLoginStore } from "hooks/Login";
import AdvanceFilterModal from "./AdvanceFilterModal";
import { useNotificationStore } from "hooks/Notification";
import { useAzureStore } from "hooks/Azure";
import CreateBillRepository from "repository/CreateBillRepository";
import getSymbolFromCurrency from "currency-symbol-map";
import { FaRegTrashCan } from "react-icons/fa6";
import PlusMinusButton from "components/Button/PlusMinusButton";
import { IoAddCircleOutline } from "react-icons/io5";
import CustomButton from "components/Button/CustomButton";
import Common from "helpers/Common";
import moment from "moment";

const EditBill = () => {
  const [loader, setLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [manualDiscount, setManualDiscount] = useState(null);
  const [manualDiscountAmount, setManualDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [igstAmount, setIgstAmount] = useState(0);
  const [totalBeforeTax, setTotalBeforeTax] = useState(0);
  const [filteredProduct, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(null);
  const [paymentDone, setPaymentDone] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [showInRupees, setShowInRupees] = useState(false);
  const [billId, setBillId] = useState(0);
  const [igstPercentage, setIgstPercentage] = useState(18);
  const [branchDetail, setBranchDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [billData, setBillData] = useState(null);
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerData, setCustomerData] = useState({
    customerID: 0,
    customerName: "",
    address: "",
    phoneNumber: "",
    customerEmail: "",
    invoiceReference: "",
  });
  const [focusIndex, setFocusIndex] = useState(null);
  const [expiryDays, setExpiryDays] = useState(null);
  const { id } = useParams();
  const currency = new CreateBillRepository().getCurrencyAbbriviation();

  const [
    {},
    { getBill, getCustomerDetail, getProductSearchList, getBranchDetail },
    { saveBill, saveInvoice },
  ] = useCreateBillStore();
  const [{}, {}, { setAlerts }] = useLoginStore();

  const debounceTimer = useRef(null);
  const autoCompleteRef = useRef(null);
  const inputRefs = useRef([]);

  const togglePopover = (index) => {
    if (popoverOpen === index) {
      setPopoverOpen(null);
    } else {
      setPopoverOpen(index);
    }
  };

  const retrieveBranchDetail = async () => {
    let branchDetail = localStorage.getItem("branchDetail");
    if (!branchDetail) {
      branchDetail = await getBranchDetail();
    } else {
      const parsedItem = JSON.parse(branchDetail);
      const now = new Date().getTime();
      if (now > parsedItem.expiry) {
        localStorage.removeItem("branchDetail"); // Remove from localStorage if expired
      }
    }

    setBranchDetail(branchDetail);
  };

  useEffect(() => {
    // Fetch expiry_days from localStorage
    const companyProfile = JSON.parse(localStorage.getItem("companyProfile"));

    if (companyProfile) {
      const expiryDaysObj = companyProfile.find(
        (item) => item.feature_key === "expiry_days"
      );
      if (expiryDaysObj) {
        setExpiryDays(Number(expiryDaysObj.feature_value)); // Ensure it's a number
      }
    }
  }, []);

  const updateRow = (index, field, value) => {
    const updatedRows = [...rows];
    const thisRow = updatedRows[index];

    const parseAndValidate = (val) => {
      const parsedValue = parseFloat(val);
      return isNaN(parsedValue) ? 0 : parsedValue;
    };

    if (field === "medicinetime") {
      const transformedData = Object.keys(value).map((time) => ({
        time: time,
        quantity: value[time].quantity.toString(),
        afterfood: value[time].afterfood,
      }));
      thisRow.medicinetime = transformedData;
    } else {
      if (field === "qty" && thisRow?.availableQty < value) {
        setAlerts({
          type: "warning",
          message: "We do not have enough stocks which you required.",
        });
      }
      const companyProfile = JSON.parse(
        localStorage.getItem("companyProfile") || "[]"
      );
      const expiryDaysObj = companyProfile.find(
        (item) => item.feature_key === "expiry_days"
      );
      const expiryDays = expiryDaysObj
        ? parseInt(expiryDaysObj.feature_value)
        : 0; // Default to 7 days if not found
      // Check if the product's expiry date is within the 'expiryDays' threshold
      const today = moment();
      const expiry = moment(thisRow?.expiryDate); // Assuming thisRow has the expiryDate field
      const diffInDays = expiry.diff(today, "days"); // Get the difference in days

      // Compare with expiryDays from local storage
      if (diffInDays <= expiryDays) {
        setAlerts({
          type: "warning",
          message: `Product is either expired or will expire in ${expiryDays} days.`,
        });
      }
      thisRow[field] = value;
    }

    const qty = parseAndValidate(thisRow.qty);
    const rate = parseAndValidate(thisRow.rate);
    const amount = qty * rate;
    thisRow.amount = amount > 0 ? amount : 0;

    const discountAmount = parseAndValidate(thisRow.discountAmount);
    const productCost = thisRow.amount - discountAmount;
    thisRow.productCost = productCost > 0 ? productCost : 0;

    const sgst = parseAndValidate(thisRow.sgst);
    const cgst = parseAndValidate(thisRow.cgst);
    const netAmount =
      thisRow.productCost +
      (thisRow.productCost * sgst) / 100 +
      (thisRow.productCost * cgst) / 100;
    thisRow.netAmount = netAmount > 0 ? netAmount : 0;

    setRows(updatedRows);
  };

  const updateRowProduct = (index, product) => {
    if (!product.productName || !product.unitPrice) {
      setAlerts({ type: "danger", message: "Please Select a Product" });
      return;
    }

    if (product?.available_qty < 1) {
      setAlerts({
        type: "warning",
        message: "We do not have enough stocks which you required.",
      });
    }

    const companyProfile = JSON.parse(
      localStorage.getItem("companyProfile") || "[]"
    );
    const expiryDaysObj = companyProfile.find(
      (item) => item.feature_key === "expiry_days"
    );
    const expiryDays = expiryDaysObj
      ? parseInt(expiryDaysObj.feature_value)
      : 0; // Default to 7 days if not found
    // Check if the product's expiry date is within the 'expiryDays' threshold
    const today = moment();
    const expiry = moment(product?.expiryDate); // Assuming thisRow has the expiryDate field
    const diffInDays = expiry.diff(today, "days"); // Get the difference in days

    // Compare with expiryDays from local storage
    if (diffInDays <= expiryDays) {
      setAlerts({
        type: "warning",
        message: `Product is either expired or will expire in ${expiryDays} days.`,
      });
    }

    const isProductAlreadyAdded = rows.some(
      (row, i) => row.productId === product.productId && i !== index
    );

    if (isProductAlreadyAdded) {
      setAlerts({
        type: "warning",
        message: "Product is already added.",
      });

      const updatedRows = [...rows];
      updatedRows[index] = { productName: "", qty: 0, rate: 0 };
      setRows(updatedRows);

      return;
    }

    const productCostBeforeTaxCal =
      product?.unitPrice - (parseFloat(product?.product_discount) || 0);

    const SGSTCost = (productCostBeforeTaxCal * product?.tax2) / 100;
    const CGSTCost = (productCostBeforeTaxCal * product?.tax1) / 100;

    // Update the row with new product details
    const newRow = {
      productName: product?.productName,
      productId: product?.productId,
      qty: 1,
      rate: product?.unitPrice,
      amount: product?.unitPrice,
      discountAmount: parseFloat(product?.product_discount) || 0,
      productCost: productCostBeforeTaxCal,
      sgst: product?.tax2,
      expiryDate: product?.expiryDate,
      availableQty: product?.available_qty,
      cgst: product?.tax1,
      netAmount: productCostBeforeTaxCal + SGSTCost + CGSTCost,
    };
    const updatedRows = [...rows];
    updatedRows[index] = newRow; // Replace the row at the given index
    setRows(updatedRows);
  };

  const retriveCustomers = async (queryText) => {
    let customerList = await getCustomerDetail(queryText);
    setFilteredCustomer(customerList);
  };

  const filterCustomer = (event) => {
    retriveCustomers(event.query);
  };

  const fetchBillData = async (billId) => {
    const fetchBillData = await getBill(billId);
    setBillData(fetchBillData);
    setSelectedCustomer(fetchBillData.customerName);
    setCustomerData({
      customerID: fetchBillData.customerId,
      customerName: fetchBillData.customerName,
      address: fetchBillData.customerAddress,
      phoneNumber: fetchBillData.customerContact,
      customerEmail: fetchBillData.customerEmail,
      invoiceReference: fetchBillData.invoiceReferenceNumber,
    });
    setRows(fetchBillData.productInfos);
    setManualDiscount(fetchBillData.manualDiscount);
    setTotalAmount(fetchBillData.grandTotal);
    setTotalBeforeTax(fetchBillData.totalBeforeTax);
    setIgstPercentage(fetchBillData.gst);
    setBillId(billId);

    const isPercentage =
      fetchBillData.manualDiscountType === "percentage" ? true : false;
    setShowInRupees(!isPercentage);
    setPaymentDone(fetchBillData.paidStatus);
    setPaymentMethod(fetchBillData.paymentMode);
    setSubTotal(fetchBillData.subTotal);
    setLoader(false);
  };

  const toggleCollapseBtn = () => {
    const parentElement = document.getElementsByClassName("navbar-minimize")[0];

    if (parentElement) {
      const btn = parentElement.querySelector("#minimizeSidebar");

      if (btn) {
        const icon = parentElement.querySelector(".nc-minimal-right");

        if (icon) {
          btn.click();
        }
      }
    }
  };

  useEffect(() => {
    setLoader(true);
    retriveCustomers();
    toggleCollapseBtn();
    fetchBillData(id);
    retrieveBranchDetail();

    if (autoCompleteRef.current) {
      autoCompleteRef.current.focus();
    }

    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    const total = parseFloat(
      rows.reduce((acc, row) => acc + parseFloat(row.netAmount), 0)
    );
    setSubTotal(total);

    const manualDiscountAmountCalculation = showInRupees
      ? manualDiscount !== null
        ? parseFloat(manualDiscount)
        : 0.0
      : (total * manualDiscount) / 100;

    const totalWithDiscount =
      total > 0 ? total - manualDiscountAmountCalculation : 0.0; // Apply manual discount

    setManualDiscountAmount(manualDiscountAmountCalculation);

    if (igstPercentage >= 0 && igstPercentage <= 100) {
      const igst = (totalWithDiscount * igstPercentage) / 100; // Calculate IGST
      setIgstAmount(igst); // Set IGST amount

      setTotalBeforeTax(totalWithDiscount);
      setTotalAmount(totalWithDiscount + igst);
    }
  }, [
    rows,
    updateRowProduct,
    updateRow,
    manualDiscount,
    showInRupees,
    igstPercentage,
  ]);

  useEffect(() => {
    // This will set focus to the last input box in the array of refs whenever a new row is added.
    const lastRef = inputRefs.current[rows.length - 1];
    if (lastRef) {
      lastRef.focus();
    }
  }, [rows]);

  useEffect(() => {
    if (focusIndex !== null) {
      const input = document.getElementById(`qty-input-${focusIndex}`);
      if (input) {
        input.focus();
      }
    }
  }, [focusIndex]);

  const retriveProducts = async (queryText) => {
    if (queryText.length >= 2) {
      // Check if at least 2 characters are entered
      let productList = await getProductSearchList(queryText);
      if (productList && !isEmpty(productList)) {
        setFilteredProducts(productList);
      } else {
        /**
         * display no result found when no data get in response
         * Author: Devanshi Patel
         */
        setFilteredProducts([
          {
            productName: (
              <span style={{ cursor: "none", padding: "70px" }}>
                no result found
              </span>
            ),
          },
        ]);
      }
    } else {
      /**
       * Display error when only 1 character is type for product search auto complete
       * Author: Devanshi Patel
       */
      setTimeout(() => {
        setAlerts({
          type: "danger",
          message: "Minimum 2 characters are needed for product search",
        });
      }, 500);
    }
  };

  const filterProduct = (event) => {
    const query = event.query;

    // Clear the existing timer if any
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new timer
    debounceTimer.current = setTimeout(() => {
      retriveProducts(query);
    }, 1000); // 1s delay
  };

  const addRow = (data) => {
    if (!data.productName || !data.unitPrice) {
      setAlerts({
        type: "danger",
        message:
          "Please select a product to the current row to add a new Product.",
      });
      return;
    }
    const isProductAlreadyAdded = rows.some(
      (row) => row.productId === data.productId
    );

    if (isProductAlreadyAdded) {
      setAlerts({
        type: "warning",
        message: "Product is already added.",
      });
      return;
    }

    if (data?.available_qty < 1) {
      setAlerts({
        type: "warning",
        message: "We do not have enough stocks which you required.",
      });
    }

    const companyProfile = JSON.parse(
      localStorage.getItem("companyProfile") || "[]"
    );
    const expiryDaysObj = companyProfile.find(
      (item) => item.feature_key === "expiry_days"
    );
    const expiryDays = expiryDaysObj
      ? parseInt(expiryDaysObj.feature_value)
      : 0; // Default to 7 days if not found
    // Check if the product's expiry date is within the 'expiryDays' threshold
    const today = moment();
    const expiry = moment(data?.expiryDate); // Assuming thisRow has the expiryDate field
    const diffInDays = expiry.diff(today, "days"); // Get the difference in days

    // Compare with expiryDays from local storage
    if (diffInDays <= expiryDays) {
      setAlerts({
        type: "warning",
        message: `Product is either expired or will expire in ${expiryDays} days.`,
      });
    }

    const productCostBeforeTaxCal =
      data?.unitPrice - (parseFloat(data?.product_discount) || 0);

    const SGSTCost = (productCostBeforeTaxCal * data?.tax2) / 100;
    const CGSTCost = (productCostBeforeTaxCal * data?.tax1) / 100;

    const newRow = {
      productId: data.productId,
      productDescription: data.productDescription,
      productName: data.productName,
      categoryId: data.categoryId,
      expiryDate: data.expiryDate,
      availableQty: data?.available_qty,
      qty: 1,
      rate: data.unitPrice,
      amount: data.unitPrice,
      discountAmount: parseFloat(data?.product_discount) || 0,
      productCost: productCostBeforeTaxCal,
      sgst: data.tax2,
      cgst: data.tax1,
      netAmount: productCostBeforeTaxCal + SGSTCost + CGSTCost,
    };

    const newRows = [...rows, newRow];

    setRows([...rows, newRow]);

    setFocusIndex(newRows.length - 1);
  };

  const deleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const resetFields = () => {
    setSelectedProducts(null);
  };

  const savingBill = async () => {
    const productInfoData = rows.map((item, index) => {
      return {
        medicinetime: item.medicinetime,
        productId: item.productId,
        productName: item.productName,
        qty: item.qty,
        rate: item.rate,
        cgst: item.cgst,
        cgstType: "PERCENTAGE",
        sgst: item.sgst,
        sgstType: "PERCENTAGE",
        discountAmount: item.discountAmount,
        productDiscountType: "AMOUNT",
        amount: item.amount.toFixed(2),
        productCost: item.productCost.toFixed(2),
        netAmount: parseFloat(item.netAmount).toFixed(2),
        expiryDate: item.expiryDate,
        sgstTotalTaxAmount: ((item.amount * item.sgst) / 100).toFixed(2),
        cgstTotalTaxAmount: ((item.amount * item.cgst) / 100).toFixed(2),
      };
    });

    const params = {
      companyId: Common.getCompanyId(),
      userId: Common.getUserId(),
      companyName: Common.getCompanyName(),
      companyAddress: Common.getCompanyAddress(),
      companyPhone: Common.getCompanyPhone(),
      companyEmail: Common.getCompanyEmail(),
      companyGSTN: Common.getGstIn(),
      footerText: "Disclaimer and Return Policy",
      invoiceFileName: billId + ".pdf",
      invoiceDate: Common.getFormatedDate(
        moment().utc(),
        "DD-MMMM-YYYY hh:mm:ss",
        true
      ),
      editableStatus: 1,
      refundPossible: 1,
      billId: billId,
      billDescription: "Invoice for 1 Medicine",
      invoiceReferenceNumber: "ERP111",
      refundBillId: 0,
      customerId: customerData.customerID,
      customerName: customerData.customerName,
      customerAddress: customerData.address,
      customerContact: customerData.phoneNumber,
      customerEmail: customerData.customerEmail,
      productInfos: productInfoData,
      subTotal: parseFloat(subTotal).toFixed(2),
      manualDiscount: manualDiscount ? manualDiscount : "0.00",
      manualDiscountAmount: manualDiscountAmount
        ? manualDiscountAmount.toFixed(2)
        : "0.00",
      manualDiscountType: showInRupees ? "RUPEES" : "PERCENTAGE",
      totalBeforeTax: parseFloat(totalBeforeTax).toFixed(2),
      totalTax: igstAmount.toFixed(2),
      gst: igstPercentage,
      gstType: "PERCENTAGE",
      paidStatus: true,
      paymentMode: paymentMethod,
      grandTotal: parseFloat(totalBeforeTax)?.toFixed(2),
    };
    const result = await saveInvoice(params);
    setBillId(result.billId);

    if (!isEmpty(result) && result.billId) {
      setLoading(false);
      setLoadingPrint(false);

      await setAlerts({
        type: "success",
        message: `Bill No.${result.billId} has been successfully modified.`,
      });
    }
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <div className="create-bill-container">
          <div className="mb-3 d-flex align-items-center justify-content-between">
            <h4 className="title m-0">Bills / Recent Bills / Edit Bill</h4>
          </div>

          <Card>
            <div
              className="mb-3 pb-4"
              style={{ borderBottom: "1px solid #E0E0E0" }}
            >
              <Row>
                <Col xs="12" md="12" lg="12">
                  <div className="title-row">
                    <h4>Customer Details</h4>
                  </div>
                </Col>
              </Row>

              <Row className="align-items-center" style={{ rowGap: "10px" }}>
                <Col xs="12" sm="12" md="6" lg="3">
                  <AutoComplete
                    ref={autoCompleteRef}
                    value={selectedCustomer}
                    suggestions={filteredCustomer}
                    completeMethod={filterCustomer}
                    field="customerName"
                    placeholder="Customer Name"
                    minLength={1}
                    onChange={(e) => {
                      setSelectedCustomer(e.value);
                      const newAddress = `${e.value?.address1 ?? ""}, ${
                        e.value?.address2 ?? ""
                      }\n${e.value?.city ?? ""}, ${e.value?.state ?? ""}`;
                      customerData.customerName = e.value?.customerName ?? "";
                      customerData.address = newAddress;
                      customerData.phoneNumber = e.value?.phoneNumber ?? "";
                    }}
                    pt={{
                      panel: {
                        className: "autocomplete-Zindex",
                      },
                      input: {
                        root: {
                          className: "custom-width-input form-control",
                        },
                      },
                    }}
                    className="custom-autocomplete"
                    delay={300}
                  />
                </Col>

                <Col xs="12" sm="12" md="6" lg="3">
                  <AutoComplete
                    ref={autoCompleteRef}
                    value={customerData?.phoneNumber}
                    suggestions={filteredCustomer}
                    completeMethod={filterCustomer}
                    field="customerName"
                    placeholder="Mobile Number"
                    minLength={1}
                    onChange={(e) => {
                      setSelectedCustomer(e.value);
                      const newAddress = `${e.value?.address1 ?? ""}, ${
                        e.value?.address2 ?? ""
                      }\n${e.value?.city ?? ""}, ${e.value?.state ?? ""}`;
                      customerData.customerName = e.value?.customerName ?? "";
                      customerData.address = newAddress;
                      customerData.phoneNumber = e.value?.phoneNumber ?? "";
                    }}
                    pt={{
                      panel: {
                        className: "autocomplete-Zindex",
                      },
                      input: {
                        root: {
                          className: "custom-width-input form-control",
                        },
                      },
                    }}
                    className="custom-autocomplete"
                    delay={300}
                  />
                </Col>

                <Col xs="12" sm="12" md="6" lg="3">
                  <AutoComplete
                    ref={autoCompleteRef}
                    value={customerData?.customerEmail}
                    suggestions={filteredCustomer}
                    completeMethod={filterCustomer}
                    field="customerName"
                    placeholder="Email"
                    minLength={1}
                    onChange={(e) => {
                      setSelectedCustomer(e.value);
                      const newAddress = `${e.value?.address1 ?? ""}, ${
                        e.value?.address2 ?? ""
                      }\n${e.value?.city ?? ""}, ${e.value?.state ?? ""}`;
                      customerData.customerName = e.value?.customerName ?? "";
                      customerData.address = newAddress;
                      customerData.phoneNumber = e.value?.phoneNumber ?? "";
                    }}
                    pt={{
                      panel: {
                        className: "autocomplete-Zindex",
                      },
                      input: {
                        root: {
                          className: "custom-width-input form-control",
                        },
                      },
                    }}
                    className="custom-autocomplete"
                    delay={300}
                  />
                </Col>
              </Row>
            </div>

            <>
              <div className="title-row">
                <h4>
                  Purchase Details : Invoice Reference :{" "}
                  {customerData?.invoiceReference}
                </h4>
              </div>
              <div style={{ overflowX: "auto" }}>
                <table className="purchase-table">
                  <thead>
                    <tr>
                      <th className="product-name-col">Particulars</th>
                      <th className="qty-col">Quantity</th>
                      <th className="expiry-col">Expiry Date</th>
                      <th className="rate-col">Rate</th>
                      <th className="amount-col">Amount</th>
                      <th className="discount-col">Discount</th>
                      <th className="total-price-col">Total</th>
                      <th className="sgst-col">SGST (%)</th>
                      <th className="cgst-col">CGST (%)</th>
                      <th className="net-amount-col">Net Amount</th>
                      <th className="action-btn-col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="product-name-col cutomize-form-control">
                            <div className="product-seach-input">
                              <AutoComplete
                                className="custom-autocomplete"
                                value={row?.productName}
                                suggestions={filteredProduct}
                                completeMethod={(e) =>
                                  filterProduct(e, "update", index)
                                }
                                field="productName"
                                placeholder="Search by Product Name - at least 2 characters"
                                minLength={1}
                                onChange={(e) => {
                                  updateRow(index, "productName", e.value);
                                }}
                                onSelect={(e) => {
                                  updateRowProduct(index, e.value);
                                }}
                                pt={{
                                  panel: {
                                    className: "autocomplete-Zindex",
                                  },
                                }}
                                delay={300}
                              />
                              <AdvanceFilterModal
                                addRow={addRow}
                                setSelectedProducts={setSelectedProducts}
                                index={index}
                                updateRowProduct={updateRowProduct}
                              />
                            </div>
                          </td>
                          <td className="qty-col cutomize-form-control">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <input
                                style={{
                                  height: "34.5px",
                                  fontSize: "14px",
                                  paddingRight: "8px",
                                  width: "60%",
                                  boxSizing: "border-box",
                                  backgroundColor:
                                    row?.availableQty < 1 ||
                                    moment(row?.expiryDate).diff(
                                      moment(),
                                      "days"
                                    ) <= expiryDays
                                      ? "#f0f0f0"
                                      : "white",
                                }}
                                className="cutomize-form-control-input custom-number-input"
                                id={`qty-input-${index}`}
                                type="text"
                                value={row?.qty}
                                onChange={(e) => {
                                  const newQty = parseFloat(e.target.value);
                                  updateRow(
                                    index,
                                    "qty",
                                    isNaN(newQty) ? "" : newQty
                                  );
                                }}
                                maxLength={100}
                                disabled={
                                  row?.availableQty < 1 ||
                                  moment(row?.expiryDate).diff(
                                    moment(),
                                    "days"
                                  ) <= expiryDays
                                }
                              />

                              {row?.availableQty < 1 && (
                                <div
                                  onClick={() =>
                                    window.open(
                                      `/admin/inventorymanual?productName=${encodeURIComponent(
                                        row?.productName
                                      )}`,
                                      "_blank" // This opens the URL in a new tab
                                    )
                                  }
                                  style={{
                                    backgroundColor: "#4a6cf7",
                                    borderRadius: "8px",
                                    padding: "6px",
                                    color: "white",
                                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                    marginLeft: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "34.5px",
                                    height: "34.5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <RiAddFill size={16} />
                                </div>
                              )}
                            </div>
                          </td>
                          <td
                            className="expiry-col cutomize-form-control p-0"
                            style={{
                              fontSize: "15px",
                              color:
                                moment(row?.expiryDate).diff(
                                  moment(),
                                  "days"
                                ) <= expiryDays
                                  ? "red" // Optional visual indicator for disabled
                                  : "green",
                            }}
                          >
                            {Common.getFormatedDate(
                              row?.expiryDate,
                              "DD MMM YYYY",
                              true
                            ) !== "Invalid date"
                              ? Common.getFormatedDate(
                                  row?.expiryDate,
                                  "DD MMM YYYY",
                                  true
                                )
                              : "N/A"}
                          </td>
                          <td className="rate-col cutomize-form-control">
                            <Input
                              className="custom-number-input"
                              type="number"
                              value={row?.rate}
                              onChange={(e) => {
                                const newRate = parseFloat(e.target.value);
                                updateRow(
                                  index,
                                  "rate",
                                  Math.max(0, isNaN(newRate) ? 0 : newRate)
                                );
                              }}
                              disabled={
                                row?.availableQty < 1 ||
                                moment(row?.expiryDate).diff(
                                  moment(),
                                  "days"
                                ) <= expiryDays
                              }
                              style={{
                                backgroundColor:
                                  row?.availableQty < 1 ||
                                  moment(row?.expiryDate).diff(
                                    moment(),
                                    "days"
                                  ) <= expiryDays
                                    ? "#f0f0f0"
                                    : "white", // Grey background for disabled, white otherwise
                              }}
                            />
                          </td>
                          <td className="amount-col cutomize-form-control">
                            {row?.amount?.toFixed(2)}
                          </td>
                          <td className="discount-col cutomize-form-control">
                            <Input
                              className="cutomize-form-control-input custom-number-input"
                              type="number"
                              value={
                                isNaN(row?.discountAmount)
                                  ? ""
                                  : row?.discountAmount
                              }
                              onChange={(e) => {
                                const newDiscount = parseFloat(e.target.value);
                                updateRow(
                                  index,
                                  "discountAmount",
                                  Math.max(
                                    0,
                                    isNaN(newDiscount) ? 0 : newDiscount
                                  )
                                );
                              }}
                              disabled={row?.availableQty < 1}
                              style={{
                                backgroundColor:
                                  row?.availableQty < 1 ||
                                  moment(row?.expiryDate).diff(
                                    moment(),
                                    "days"
                                  ) <= expiryDays
                                    ? "#f0f0f0"
                                    : "white", // Grey background for disabled, white otherwise
                                flex: 1,
                                textAlign: "center", // Center the text in the input field
                                borderRadius: "5px", // Optional: To match the rounded corners in your design
                                padding: "5px", // Optional: Adjust padding for better visual appearance
                              }}
                            />
                          </td>
                          <td className="amount-col cutomize-form-control">
                            {row?.productCost?.toFixed(2)}
                          </td>
                          <td className="sgst-col cutomize-form-control">
                            <PlusMinusButton
                              plusBtnClickHandler={() =>
                                row.sgst >= 0
                                  ? updateRow(index, "sgst", row.sgst + 1)
                                  : null
                              }
                              minusBtnClickHandler={() =>
                                row.sgst > 0
                                  ? updateRow(index, "sgst", row.sgst - 1)
                                  : null
                              }
                              type="number"
                              value={row.sgst || 0}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                updateRow(
                                  index,
                                  "sgst",
                                  Math.max(0, isNaN(value) ? 0 : value)
                                );
                              }}
                            />
                          </td>

                          <td className="cgst-col cutomize-form-control">
                            <PlusMinusButton
                              plusBtnClickHandler={() =>
                                row.cgst >= 0
                                  ? updateRow(index, "cgst", row.cgst + 1)
                                  : null
                              }
                              minusBtnClickHandler={() =>
                                row.cgst > 0
                                  ? updateRow(index, "cgst", row.cgst - 1)
                                  : null
                              }
                              type="number"
                              value={row.cgst || 0}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                updateRow(
                                  index,
                                  "cgst",
                                  Math.max(0, isNaN(value) ? 0 : value)
                                );
                              }}
                            />
                          </td>
                          <td className="net-amount-col cutomize-form-control">
                            {row?.netAmount?.toFixed(2)}
                          </td>
                          <td className="action-btn-col">
                            <FaRegTrashCan
                              className="create-bill-icon delete-row-icon"
                              id={`Popover-${index}`}
                              onClick={() => togglePopover(index)}
                            />
                            <Popover
                              placement="bottom"
                              isOpen={popoverOpen === index}
                              target={`Popover-${index}`}
                              toggle={() => togglePopover(index)}
                            >
                              <PopoverBody style={{ textAlign: "center" }}>
                                <span
                                  style={{
                                    display: "block",
                                    marginBottom: "5px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  Are you sure you want to delete it?
                                </span>
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => {
                                    deleteRow(index);
                                    togglePopover(index);
                                  }}
                                >
                                  Delete
                                </Button>
                                <Button
                                  color="secondary"
                                  size="sm"
                                  onClick={() => togglePopover(index)}
                                >
                                  Cancel
                                </Button>
                              </PopoverBody>
                            </Popover>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                    <tr>
                      <td className="product-name-col">
                        <div className="product-seach-input">
                          <AutoComplete
                            ref={autoCompleteRef}
                            className="custom-autocomplete customize-autocomplete"
                            value={selectedProducts}
                            suggestions={filteredProduct}
                            completeMethod={filterProduct}
                            field="productName"
                            placeholder="Search by Product Name"
                            minLength={1}
                            onChange={(e) => {
                              setSelectedProducts(e.value);
                            }}
                            onSelect={(e) => {
                              addRow(e.value);
                              resetFields();
                            }}
                            pt={{
                              panel: {
                                className: "autocomplete-Zindex",
                              },
                            }}
                            delay={300}
                          />

                          <AdvanceFilterModal
                            addRow={addRow}
                            setSelectedProducts={setSelectedProducts}
                          />
                        </div>
                      </td>
                      <td className="qty-col cutomize-form-control">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <input
                            style={{
                              height: "34.5px",
                              fontSize: "14px",
                              paddingRight: "8px",
                              width: "60%",
                              boxSizing: "border-box",
                            }}
                            className="custom-number-input"
                            type="text"
                            value={0}
                            min="0"
                            maxLength={100}
                          />
                        </div>
                      </td>
                      <td className="rate-col cutomize-form-control">
                        <Input
                          className="custom-number-input"
                          type="number"
                          value={0}
                          min="0"
                        />
                      </td>
                      <td className="amount-col cutomize-form-control">{0}</td>
                      <td className="discount-col cutomize-form-control">
                        <Input
                          className="custom-number-input"
                          type="number"
                          value={0}
                          min="0"
                        />
                      </td>
                      <td className="total-price-col cutomize-form-control">
                        0{" "}
                      </td>
                      <td className="sgst-col cutomize-form-control">0</td>
                      <td className="cgst-col cutomize-form-control">0</td>
                      <td className="net-amount-col cutomize-form-control">
                        0
                      </td>
                      <td className="action-btn-col">
                        <IoAddCircleOutline
                          className="create-bill-icon"
                          onClick={addRow}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="invoice-summary-container">
                <Row className="w-100">
                  <Col xs={12} sm={12} md={12} lg={8}>
                    <Row>
                      <Col md={12}>
                        <div className="d-flex align-items-center mb-2">
                          <h4 className="title">Invoice & Payment</h4>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ rowGap: "10px" }}>
                      <Col sx={12} sm={12} md={4} lg={4}>
                        <div className="invoice-form-control">
                          <label>Payment Mode</label>
                          <select
                            id="payment"
                            name="payment"
                            value={paymentMethod}
                            className="invoice-payment-mode-control"
                            onChange={(e) => setPaymentMethod(e.target.value)}
                          >
                            <option value="cash">Cash</option>
                            <option value="credit/ debit card">
                              Credit/Debit Card
                            </option>
                            <option value="upi">UPI</option>
                            <option value="net banking">Net Banking</option>
                          </select>
                        </div>
                      </Col>

                      <Col sx={12} sm={12} md={4} lg={4}>
                        <div className="invoice-form-control">
                          <label>
                            Manual Discount ({showInRupees ? "₹" : "%"})
                          </label>
                          <div className="custom-manual-discount-control">
                            <input
                              type="number"
                              min="0"
                              max="100"
                              value={manualDiscount}
                              style={{ width: "130px" }}
                              className="manual-discount-input"
                              onChange={(e) => {
                                let value = parseFloat(e.target.value);
                                if (isNaN(value)) {
                                  value = "";
                                }

                                if (!showInRupees) {
                                  if (value > 100) {
                                    value = 100;
                                  } else if (value < 0) {
                                    value = 0;
                                  }
                                }
                                setManualDiscount(value);
                              }}
                            />
                            <Input
                              id="discount"
                              name="discount"
                              type="select"
                              value={showInRupees}
                              className="type-dropdown"
                              onChange={(e) => setShowInRupees(!showInRupees)}
                            >
                              <option value={true}>Fixed</option>
                              <option value={false}>Percentage</option>
                            </Input>
                          </div>
                        </div>
                      </Col>

                      {/* <Col sx={12} sm={12} md={4} lg={4}>
                        <div className="invoice-form-control">
                          <label htmlFor="">IGST (%)</label>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              className="manual-igst-minus-btn"
                              onClick={() => setIgstPercentage(igstPercentage - 1)}
                            >
                              -
                            </button>
                            <input
                              type="number"
                              min="0"
                              max="100"
                              value={igstPercentage || 0}
                              className="manual-igst-input"
                              onChange={(e) => {
                                setIgstPercentage(e.target.value);
                              }}
                              onKeyDown={(e) => handleKeyDown(e, saveBtnRef)}
                            />
                            <button
                              className="manual-igst-plus-btn"
                              onClick={() => setIgstPercentage(igstPercentage + 1)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </Col> */}
                    </Row>
                    <Row className="my-3" style={{ rowGap: "10px" }}>
                      <Col sm={12} md={6}>
                        <div className="invoice-form-control">
                          <label htmlFor="">Comments</label>
                          <Input
                            id="comment"
                            name="comment"
                            placeholder="Write down.."
                            type="textarea"
                            maxLength={200}
                            rows={1}
                            className="invoice-comment-control"
                          />
                        </div>
                      </Col>
                      <Col
                        sm={12}
                        md={6}
                        className="d-flex align-items-end justify-content-start pb-2"
                        style={{ gap: "10px" }}
                      >
                        <input
                          type="checkbox"
                          id="custom-checkbox"
                          className="custom-checkbox"
                          checked={paymentDone}
                          onChange={() => {
                            setPaymentDone((prev) => !prev);
                          }}
                        />
                        <p className="received-payment-label">
                          Received Payment
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    className="d-flex flex-column justify-content-between"
                  >
                    <Row style={{ gap: "15px" }}>
                      <Col md={12} className="invoice-summary-data-row">
                        <p>Sub Total</p>
                        <p style={{ color: "#483EB6" }}>
                          {(subTotal ?? 0)?.toFixed(2)}
                        </p>
                      </Col>
                      {/* New Manual Discount Row */}
                      <Col md={12} className="invoice-summary-data-row">
                        <p>Manual Discount</p>
                        <p style={{ color: "#FF0000" }}>
                          {"- " + (manualDiscountAmount ?? 0)?.toFixed(2)}
                        </p>
                      </Col>

                      {/* <Col md={12} className="invoice-summary-data-row">
                        <p>Total after Discount</p>
                        <p style={{ color: "#58AD6B" }}>{totalBeforeTax?.toFixed(2)}</p>
                      </Col>
                      <Col md={12} className="invoice-summary-data-row">
                        <p>TAX Applicable</p>
                        <p>{igstAmount?.toFixed(2)}</p>
                      </Col> */}
                      <Col
                        md={12}
                        className="invoice-summary-data-row invoice-summary-grand-total-row"
                      >
                        <p>Net Payable Amount</p>
                        <p style={{ color: "#58AD6B", fontSize: "15px" }}>
                          {" "}
                          {"(" +
                            (currency ?? "0") +
                            ") " +
                            (getSymbolFromCurrency(currency) ?? "") +
                            " " +
                            (totalBeforeTax ?? 0)?.toFixed(2)}{" "}
                        </p>
                      </Col>
                    </Row>
                    {billData?.editableStatus === 1 && (
                      <>
                        <Row className="mt-2">
                          <Col
                            md={12}
                            className="d-flex justify-content-start align-items-center"
                            style={{ gap: "10px" }}
                          >
                            <CustomButton
                              label="Modify"
                              disabled={loading}
                              onClick={() => savingBill()}
                            />

                            <CustomButton
                              label={
                                loadingPrint ? (
                                  <>
                                    <span className="loading-text">
                                      Saving...
                                    </span>{" "}
                                  </>
                                ) : (
                                  "Modify & Print"
                                )
                              }
                              disabled={loadingPrint}
                              onClick={() => savingBill()}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col sm={12} md={12}>
                        {billData?.editableStatus === 0 && (
                          <center>
                            <div style={{ color: "red", fontSize: "20px" }}>
                              You are not allowed to perform this operation.
                            </div>
                          </center>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </>
          </Card>
        </div>
      )}
    </>
  );
};

export default EditBill;

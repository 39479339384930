import React, { useState } from "react";
import { Bar } from "react-chartjs-2";

// reactstrap components
import { Card, CardBody, Row, Col } from "reactstrap";

// core components
import { useReportStore } from "hooks/Report";
import { useEffect } from "react";
import { isArray, isEmpty } from "underscore";
import moment from "moment";
import Common from "helpers/Common";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PageLoader from "components/Loader/PageLoader";
import FilterButton from "./FilterButton";
import DownloadReport from "./DownloadReport";
import ChildLoader from "components/Loader/ChildLoader";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import LoadMore from "components/Button/LoadMore";
import 'assets/styles/analytics/SalesReport.css';
import CustomButton from "components/Button/CustomButton";
import { URL_REVENUE_REPORT } from "helpers/Paths";
import { Link } from "react-router-dom";
import { URL_HISTORICAL_DEMAND_REPORT } from "helpers/Paths";

const MaximumStockUsageReport = () => {
  const [{ }, { getMaximumStockUsageReport }, { }] = useReportStore();
  const [maxReportData, setMaxReportData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState(Common.getLastMonthDate());
  const [endDate, setEndDate] = useState(Common.getTodayDate());

  const [labels, setLabels] = useState([]);
  const [productQuantity, setProductQuantity] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const MAX_RECORDS = 15;

  const loadMoreData = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
    setLoadingMore(true);
    getReportData(pageNumber + 1); // Increment page number for next API call
  };

  function getThreeMonthsAgo() {
    const today = new Date();
    today.setMonth(today.getMonth() - 3); // Subtract 3 months from the current month
    return today;
  }

  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            return `Quantity: ${context.raw}`;
          }
        },
        displayColors: false,
        backgroundColor: '#585858',
        titleColor: '#ffffff',
        bodyColor: '#ffffff',
        padding: 10,
        cornerRadius: 4
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          padding: 20,
          color: "#9f9f9f",
          maxRotation: 0,
          minRotation: 0,
          callback: function (value) {
            const label = this.getLabelForValue(value);
            const words = label.split(' ');
            const maxLineLength = 10;
            let line = '';
            let lines = [];
            words.forEach(word => {
              if ((line + word).length > maxLineLength) {
                lines.push(line.trim());
                line = '';
              }
              line += word + ' ';
            });
            lines.push(line.trim());
            return lines;
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          borderDash: [5, 5],
        }
      },
    },
  };

  let initialReportState = {
    labels: [],
    datasets: [],
  };

  const [chartData, setChartData] = useState(initialReportState);

  const setChartDetails = (
    allLabels,
    allProductQuantity,
    label = "Product Quantity"
  ) => {

    const maxValue = Math.max(...allProductQuantity);

    const chartDetails = {
      labels: allLabels,
      datasets: [
        {
          label: label,
          data: allProductQuantity,
          backgroundColor: allProductQuantity.map(value =>
            value === maxValue ? '#3146F5' : '#B5BCFB'
          ),
          borderRadius: 10,
          maxBarThickness: 50
        },
      ],
    };
    setChartData(chartDetails);
  };

  async function getReport(Date = "", endDate = "", page = 1) {
    setSelectedDate({ fromDate: Date, toDate: endDate })
  }

  useEffect(() => {
    setLoader(true);
    if (selectedDate) {
      getReportData();
    }
  }, [selectedDate]);

  async function getReportData(page = 1) {
    setLoader(true);
    if (page === 1) {
      setPageNumber(1);
    }

    let params = {};
    params.pagenumber = page;
    params.pagesize = new CompanyProfileRepository().getPageSize();
    params.from_date = selectedDate.fromDate;
    params.to_date = selectedDate.toDate;

    let maxStockUsageReportData = await getMaximumStockUsageReport(params);

    let arr = [];
    let productQuantities = {};
    if (
      maxStockUsageReportData &&
      isArray(maxStockUsageReportData) &&
      !isEmpty(maxStockUsageReportData)
    ) {
      maxStockUsageReportData.map((prop, key) => {
        // Inside your map function
        let createDateObj = moment(prop.createDateTime);
        let formattedDate = createDateObj.format("MMMM D, YYYY");
        let formattedTime = createDateObj.format("hh:mm A");

        let formattedDateTime = `${formattedDate}, ${formattedTime}`;

        let obj = {
          productID: prop.productID,
          productName: prop.productName,
          netQtySale: prop.netQtySale,
          timePeriod: prop.time_period,
          totalUnit: prop.totalUnit,
          dateTime: formattedDateTime,
        };

        arr.push(obj);
        if (productQuantities[prop.productName]) {
          productQuantities[prop.productName] += prop.netQtySale;
        } else {
          productQuantities[prop.productName] = prop.netQtySale;
        }
      });
    }

    let labelsData = Object.keys(productQuantities);
    let productQuantityData = Object.values(productQuantities);

    if (page > 1) {
      // Append new data to existing data for subsequent pages
      setMaxReportData((prevData) => [...prevData, ...arr]);
      setLabels((prevData) => [...prevData, ...labelsData]);
      setProductQuantity((prevData) => [...prevData, ...productQuantityData]);

      const salesinfo = [...productQuantity, ...productQuantityData];
      const labelsInfo = [...labels, ...labelsData];

      if (salesinfo.length > MAX_RECORDS) {
        let combinedData = labelsInfo.map((label, index) => ({
          label,
          salesQuantity: salesinfo[index],
        }));

        combinedData.sort((a, b) => b.salesQuantity - a.salesQuantity);
        combinedData = combinedData.slice(0, MAX_RECORDS);

        let bestSellerlabelsData = combinedData.map((data) => data.label);
        let bestSellerproductQuantityData = combinedData.map(
          (data) => data.salesQuantity
        );

        setChartDetails(
          bestSellerlabelsData,
          bestSellerproductQuantityData,
          `Top ${MAX_RECORDS} Best Selling Products`
        );

        setLoadingMore(false);
        setLoader(false);
      } else {
        const allLabels = [...labels, ...labelsData];
        const allProductQuantity = [...productQuantity, ...productQuantityData];
        setChartDetails(allLabels, allProductQuantity);

        setLoadingMore(false);
        setLoader(false);
      }
    } else {
      // Set new data for the first page
      setMaxReportData(arr);
      setLabels(labelsData);
      setProductQuantity(productQuantityData);
      setChartDetails(labelsData, productQuantityData);
      setLoader(false);
    }

    setLoadingFilter(false);
  }

  const headers = [
    { label: "Product ID", key: "productID" },
    { label: "Product Name", key: "productName" },
    { label: "Net Quantity Sale", key: "netQtySale" },
    { label: "totalUnit", key: "totalUnit" },
    { label: "Bill Creation Date", key: "dateTime" },
  ];

  return (
    <>
      <div className="content-dashboard-report">
        {/* <div className="d-flex align-items-center justify-content-between">
            <h4 className="title">BEST SELLING REPORT</h4>
            <div className="border-0">
              <FilterButton
                getReport={getReport}
                setLoadingFilter={setLoadingFilter}
              />
            </div>
          </div> */}

        <div className="d-flex justify-content-between align-items-center">
          <h4 className="title">Reports / <Link to={`/admin${URL_HISTORICAL_DEMAND_REPORT}`}>Sales Report</Link> / Best Selling Report</h4>
        </div>

        <Row>
          <Col lg="12" sm="12">
            <Card className="chart-container">
              <CardBody>
                <>
                  <div className="selling-header">
                    <h5>Best Selling Report</h5>
                    <div className="border-0">
                      <FilterButton
                        getReport={getReport}
                        setLoadingFilter={setLoadingFilter}
                      />
                    </div>
                  </div>
                  <div className="bar-chart-wrapper">
                    <div className="bar-chart-container">
                      <Bar
                        data={chartData}
                        options={options}
                        height={400}
                        width={820}
                      />
                    </div>
                  </div>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="report-table-card">
              <CardBody>
                <div className="report-table-title">
                  <h5>Maximum Stock Usage Report</h5>
                  <DownloadReport
                    reportData={maxReportData}
                    headers={headers}
                  />
                </div>
                {loadingFilter ? (
                  <ChildLoader />
                ) : (
                  <>
                    <DataTable
                      value={maxReportData}
                      tableStyle={{ minWidth: "25rem" }}
                      className="custom-report-table"
                    >
                      <Column
                        field="productID"
                        header="Product ID"
                        className="product-id-col"
                      ></Column>
                      <Column
                        field="productName"
                        header="Product Name"
                      ></Column>
                      <Column
                        field="netQtySale"
                        header="Net Quantity Sale"
                        align="center"
                      ></Column>
                      <Column
                        field="totalUnit"
                        header="Total Units"
                        align="center"
                      ></Column>
                      <Column
                        field="dateTime"
                        header="Bill Creation Date"
                        dataType="date"
                        align="center"
                      ></Column>
                    </DataTable>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            {loadingMore ? (
              <ChildLoader />
            ) : (
              <LoadMore totalData={maxReportData.length} handleLoadMore={() => loadMoreData()} />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default MaximumStockUsageReport;

import React from "react";
import {
  Col,
  Container,
  ListGroupItem,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import close from "assets/img/icons/createBill/close.png";
import addBill from "assets/img/icons/createBill/addBill.png";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import LoadMore from "components/Button/LoadMore";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";

const pageSize = new CompanyProfileRepository().getPageSize();

const CustomerBillHistoryModal = ({
  modal,
  toggle,
  backdrop,
  keyboard,
  customerBillHistoryData,
  customerName,
  handleLoadMore,
  tableData,
  fetchBillDataById,
}) => {
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={backdrop}
        keyboard={keyboard}
        fullscreen={"xl"}
        size={"xl"}
      >
        <ListGroupItem>
          <Row xs="2">
            <Col
              xs="11"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <h4
                className="m-0"
                toggle={toggle}
                style={{ fontWeight: "bold" }}
              >
                {customerName}'s Bill History
              </h4>
            </Col>
            <Col
              xs="1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                width: "100%",
              }}
            >
              <img
                src={`${ERP_CDN_IMAGE_URL}/close.png`}
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop if logo also fails
                  e.target.src = close;
                }}
                alt="X"
                className="create-bill-icon"
                id="close"
                onClick={toggle}
              />
            </Col>
          </Row>
        </ListGroupItem>
        <ModalBody>
          <Container>
            <div
              style={{
                maxHeight: "400px", // Adjust the height as needed
                overflowY: "auto",   // Enable vertical scroll
                overflowX: "hidden",
              }}
            >
              <table className="custom-table custom-table-font">
                <thead>
                  <tr>
                    <th style={{ width: "110px" }}>BillId</th>
                    <th style={{ width: "120px" }}>Bill Detail</th>
                    <th style={{ width: "150px" }}>NetProductPayment</th>
                    <th style={{ width: "100px" }}>ApplicableTax</th>
                    <th style={{ width: "100px" }}>NetPayment</th>
                    <th style={{ width: "100px" }}>PaidStatus</th>
                    <th style={{ width: "150px" }}>PendingPayment</th>
                    <th style={{ width: "100px" }}>PaidPayment</th>
                    <th style={{ width: "150px" }}>BillCreationDate</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="cutomize-form-control">{item.billId}</td>
                        <td className="cutomize-form-control">
                          {item.billDetail}
                        </td>
                        <td className="customize-form-control">
                          {Number(item.netProductPayment).toFixed(2)}
                        </td>
                        <td className="customize-form-control">
                          {Number(item.applicableTax).toFixed(2)}
                        </td>
                        <td className="customize-form-control">
                          {Number(item.netPayment).toFixed(2)}
                        </td>
                        <td className="customize-form-control">
                          <span
                            style={{
                              display: "inline-block",
                              padding: "0.25em 0.6em",
                              fontSize: "75%",
                              fontWeight: 700,
                              lineHeight: 1,
                              textAlign: "center",
                              whiteSpace: "nowrap",
                              verticalAlign: "baseline",
                              borderRadius: "0.25rem",
                              color: "#fff",
                              background:
                                item.paidStatus === "true"
                                  ? "linear-gradient(90deg, rgba(76,217,100,1) 0%, rgba(52,199,89,1) 100%)"
                                  : "linear-gradient(90deg, rgba(255,59,48,1) 0%, rgba(255,149,0,1) 100%)",
                            }}
                          >
                            {item.paidStatus === "true" ? "Paid" : "Unpaid"}
                          </span>
                        </td>
                        <td className="cutomize-form-control">
                          {Number(item.pendingPayment).toFixed(2)}
                        </td>
                        <td className="cutomize-form-control">
                          {Number(item.paidPayment).toFixed(2)}
                        </td>
                        <td className="customize-form-control">
                          {new Date(item.billCreationDate).toLocaleString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="loadMore-billHistory">
              <LoadMore
                totalData={tableData.length}
                handleLoadMore={() => handleLoadMore()}
              />
            </div>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CustomerBillHistoryModal;

import CustomButton from "components/Button/CustomButton";
import PageLoader from "components/Loader/PageLoader";
import Common from "helpers/Common";
import { notificationMessages } from "helpers/constantMessages";
import { URL_ADD_INVENTORY_BY_FILE } from "helpers/Paths";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import { useAzureStore } from "hooks/Azure";
import { useCreateBillStore } from "hooks/CreateBill";
import { useInventoryStore } from "hooks/Inventory";
import { useLoginStore } from "hooks/Login";
import { Divider } from "primereact/divider";
import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
    Card,
    CardBody,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Row,
} from "reactstrap";
import { isEmpty } from "underscore";

const measureOptions = [
    {
        label: "Strips",
        value: 1,
    },
    {
        label: "Tablet",
        value: 2,
    },
    {
        label: "Box",
        value: 3,
    },
];

const AddProduct = () => {
    const [inventoryValidate, setInventoryValidate] = useState({});
    const [productId, setProductId] = useState(0);
    const [categorySelect, setCategorySelect] = useState(null);
    const [subCategorySelect, setSubCategorySelect] = useState(null);
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [productName, setProductName] = useState("");
    const [brandName, setBrandName] = useState("");
    const [batchNo, setBatchNo] = useState("");
    const [upcCode, setupcCode] = useState("");
    const [sku, setSKU] = useState("");
    const [measure, setmeasure] = useState(measureOptions[0]);
    const [quantity, setQuantity] = useState(0);
    const [unUseduantity, setUnusedQuantity] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [mrp, setmrp] = useState(0);
    const [isFixedDiscount, setIsFixedDiscount] = useState(false);
    const [expiryDate, setExpiryDate] = useState("");
    const [description, setDescription] = useState("");
    const [applicableDiscount, setapplicableDiscount] = useState(0);
    const [buyPrice, setbuyPrice] = useState(0);
    const [gst, setGST] = useState(0);
    const [gstAmount, setGSTAmount] = useState(0);
    const [cgstAmount, setCGSTAmount] = useState(0);
    const [cgst, setCGST] = useState(0);
    const [sgst, setSGST] = useState(0);
    const [sgstAmount, setSgstAmount] = useState(0);
    const [sellPrice, setSellPrice] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [netBuyPrice, setNetBuyPrice] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [reOrderPoint, setReOrderPoint] = useState(0);
    const [leadTime, setLeadTime] = useState(0);
    const [sellPriceWarning, setSellPriceWarning] = useState("");
    const [isDataRetrieved, setIsDataRetrieved] = useState(false);
    const [fetchedCategories, setFetchedCategories] = useState({
        category: "",
        subCategory: "",
    });

    const [sellPriceDiscount, setSellPriceDiscount] = useState(0);
    const [isFixedSellPriceDiscount, setIsFixedSellPriceDiscount] =
        useState(false);
    const [
        sellPriceApplicableDiscountAmount,
        setSellPriceApplicableDiscountAmount,
    ] = useState(0);
    const [tabletCount, setTabletCount] = useState(1);

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [uploadedImageName, setUploadedImageName] = useState(null);
    const [displayMsg, setDisplayMsg] = useState("");
    const [loader, setLoader] = useState(false);

    const today = new Date().toISOString().split("T")[0];
    const navigate = useNavigate();
    const location = useLocation();

    const [{}, {}, { setAlerts }] = useLoginStore();
    const [
        {},
        { getSubCategoryList, getCategoryList, getProductDetailById },
        { createModifyInventory },
    ] = useInventoryStore();
    const [{}, {}, { inventoryFileUploadToAzure }] = useAzureStore();
    const [{}, { getProductSearchList }, {}] = useCreateBillStore();

    const getCategory = async () => {
        let categoryList = await getCategoryList();
        let list =
            categoryList &&
            !isEmpty(categoryList).length > 0 &&
            categoryList.map((prop, key) => {
                return {
                    value: prop.categoryID,
                    label: prop.categoryName,
                };
            });
        setCategory(list);
        if (!isEmpty(list).length > 0 && categorySelect == null) {
            setCategorySelect(list[0]);
            // setInventoryValidate({ ...inventoryValidate, category: "has-success" });
        }
    };

    const getSubCategory = async (val) => {
        let param = {
            categoryIds: val?.value.toString(),
        };
        let subCategoryList = await getSubCategoryList(param);
        let list =
            subCategoryList &&
            !isEmpty(subCategoryList).length > 0 &&
            subCategoryList.map((prop) => {
                return {
                    value: prop.subCategoryID,
                    label: prop.subCategoryName,
                };
            });
        setSubCategory(list);
        if (!isEmpty(list).length > 0 && subCategorySelect == null) {
            setSubCategorySelect(list[0]);
            // setInventoryValidate({ ...inventoryValidate, subCategory: "has-success" });
        }
    };

    const handleCategorySelect = (val) => {
        setSubCategorySelect(null);
        // getSubCategory(val);
    };

    const handleInputChange = (e, setter) => {
        setter(e.target.value);
    };

    const preventNegativeValues = (e) => {
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
    };

    const calculateTaxAndDiscount = () => {
        // if (quantity != "" && mrp != "") {
        let finalDiscAmount = 0;
        if (isFixedDiscount) {
            setapplicableDiscount(parseFloat(discount));
            calculateTaxAndFinalAmount(parseFloat(discount));
        } else {
            finalDiscAmount = (discount * mrp) / 100;
            setapplicableDiscount(parseFloat(finalDiscAmount));
            calculateTaxAndFinalAmount(parseFloat(finalDiscAmount));
        }
        // } else {
        //     setDisplayMsg(
        //         "Please enter Quantity/MRP/Discount to calculate Tax/Discounts."
        //     );
        // }
    };

    const calculateSellPriceDiscount = () => {
        let discountedPrice = 0;
        if (isFixedSellPriceDiscount) {
            discountedPrice = Number(mrp) - Number(sellPriceDiscount);
            setSellPrice(discountedPrice);
        } else {
            discountedPrice =
                Number(mrp) - (Number(mrp) * Number(sellPriceDiscount)) / 100;
            setSellPrice(discountedPrice);
        }
        setSellPriceApplicableDiscountAmount(Number(mrp) - discountedPrice);
    };

    // Function to calculate tax and final amount
    const calculateTaxAndFinalAmount = (finalDiscAmount) => {
        setSellPriceWarning("");

        let cgstAmount = (Number(mrp) * Number(cgst)) / (Number(cgst) + 100);
        let sgstAmount = (Number(mrp) * Number(sgst)) / (Number(sgst) + 100);
        let igstAmount = (Number(mrp) * Number(gst)) / (Number(gst) + 100);
        let basePrice =
            Number(mrp) -
            sgstAmount -
            cgstAmount -
            igstAmount -
            finalDiscAmount;

        let totaltaxAmount = cgstAmount + sgstAmount + igstAmount;
        let netPrice = Number(basePrice) + totaltaxAmount;
        let totalAmountP =
            parseFloat(netPrice).toFixed(2) * parseFloat(quantity).toFixed(2);

        setbuyPrice(parseFloat(basePrice).toFixed(2));
        setCGSTAmount(parseFloat(cgstAmount).toFixed(2));
        setSgstAmount(parseFloat(sgstAmount).toFixed(2));
        setGSTAmount(parseFloat(igstAmount).toFixed(2));
        setNetBuyPrice(parseFloat(netPrice).toFixed(2));
        setTotalTax(parseFloat(totaltaxAmount).toFixed(2));
        setTotalAmount(parseFloat(totalAmountP).toFixed(2));
    };

    const uploadProduct = (e) => {
        let fileSize = e.target.files[0].size / 1024 / 1024;
        if (fileSize > 2) {
            alert("Please select image size lesser than 2MB.");
            e.target.value = "";
        } else {
            let fileName =
                Common.getCompanyId() +
                "_" +
                Date.now() +
                "." +
                e.target.files[0].name.split(".")[1];
            setFileName(fileName);
            setFile(new File([e.target.files[0]], fileName));
        }
    };

    const isFormValid = () => {
        // array of fields with object {state, key, condition}, to validate
        const fields = [
            { field: categorySelect, key: "category" },
            { field: subCategorySelect, key: "subCategory" },
            {
                field: productName,
                key: "productName",
                check: (val) => val && val.trim() !== "",
            },
            { field: quantity, key: "quantity", check: (val) => val > 0 },
            { field: mrp, key: "mrp", check: (val) => val > 0 },
            { field: cgst, key: "cgst", check: (val) => val >= 0 },
            { field: sgst, key: "sgst", check: (val) => val >= 0 },
            { field: gst, key: "gst", check: (val) => val >= 0 },
        ];

        let isValid = true;

        fields.forEach(({ field, key, check }) => {
            const isFieldValid = check ? check(field) : field != null;

            setInventoryValidate((prev) => ({
                ...prev,
                [key]: isFieldValid ? "has-success" : "has-danger",
            }));

            if (!isFieldValid) {
                isValid = false;
            }
        });

        // Check if any field still has "has-danger" in the validation state
        const anyFieldInvalid =
            Object.values(inventoryValidate).includes("has-danger");

        return isValid && !anyFieldInvalid;
    };

    const handleSubmit = async (e) => {
        // When the user clicks on the button, scroll to the top of the document
        e.preventDefault();

        if (isFormValid()) {
            setLoader(true);
            let param = {
                productId: productId,
                productName: productName,
                categoryID: categorySelect.value,
                subCategoryId:
                    subCategorySelect && subCategorySelect?.value
                        ? subCategorySelect.value
                        : null,
                sizeDescription: null,
                brand: brandName,
                batchNumber: batchNo,
                upC_Code: upcCode,
                sku: sku,
                productCode: "",
                productType: "",
                productItemcode: "",
                productShortcode: "",
                quantity: quantity,
                basePrice: buyPrice,
                netPrice: netBuyPrice,
                sellPrice: parseFloat(
                    sellPrice - gstAmount - cgstAmount - sgstAmount
                ).toFixed(2), // Sell Price without Tax
                un_used_quantity: unUseduantity,
                maximumRetailPrice: mrp,
                discountPercentage:
                    discount != "" ? (!isFixedDiscount ? discount : 0) : 0,
                discountFixed:
                    discount != "" ? (isFixedDiscount ? discount : 0) : 0,
                applicableDiscountAmount:
                    applicableDiscount == "" ? 0 : applicableDiscount,
                expiryDate: expiryDate == "" ? null : expiryDate,
                cgst: cgst == "" ? 0 : cgst,
                sgst: sgst == "" ? 0 : sgst,
                gst: gst == "" ? 0 : gst,
                cgstRate: cgst == "" ? 0 : cgst,
                cgstAmount: cgstAmount,
                sgstRate: sgst == "" ? 0 : sgst,
                sgstAmount: sgstAmount,
                gstRate: gst == "" ? 0 : gst,
                gstAmount: gstAmount,
                taxAmount: totalTax,
                productImageName: fileName || uploadedImageName,
                measure: measure.value?.toString(),
                measureTabletCount: tabletCount,
                maximum_stock: 0,
                minimum_stock: 0,
                reorder_point: reOrderPoint,
                lead_time: leadTime,
                productDescription: description,
                sizeDescription: "",
                invoiceNumber: "",
                sellPriceApplicableDiscountAmount:
                    sellPriceApplicableDiscountAmount,
                sellPriceDiscountFixed:
                    sellPriceDiscount !== ""
                        ? isFixedSellPriceDiscount
                            ? sellPriceDiscount
                            : 0
                        : 0,
                sellPriceDiscountPercentage:
                    sellPriceDiscount !== ""
                        ? !isFixedSellPriceDiscount
                            ? sellPriceDiscount
                            : 0
                        : 0,
            };
            let res = await createModifyInventory(param);
            if (res?.isSuccess == true) {
                await setAlerts({
                    type: "success",
                    message: productId
                        ? notificationMessages.updateInventorySuccess
                        : notificationMessages.addInventorySuccess,
                });

                // file upload section starts
                if (file != null) {
                    let formData = new FormData();
                    formData.append("formFile", file);
                    await inventoryFileUploadToAzure(formData);
                }
                // file upload section ends
            }
            if (res?.isSuccess == false) {
                await setAlerts({
                    type: "danger",
                    message: res?.message,
                });
            }

            if (!productId) {
                reset(e);
            }
        }
        setLoader(false);
    };

    const removeImage = () => {
        setFile(null);
        setFileName(null);
    };

    const reset = (ev) => {
        ev.preventDefault();
        setCategorySelect(null);
        setSubCategorySelect(null);
        setProductName("");
        setBrandName("");
        setBatchNo("");
        setupcCode("");
        setSKU("");
        setmeasure(measureOptions[0]);
        setQuantity(0);
        setUnusedQuantity(0);
        setDiscount(0);
        setmrp(0);
        setGST(0);
        setExpiryDate("");
        setDescription("");
        setInventoryValidate({});
        setFile(null);
        setFileName("");
        setUploadedImageName(null);
        setapplicableDiscount(0);
        setIsFixedDiscount(true);
        setbuyPrice(0);
        setCGST(0);
        setSGST(0);
        setGST(0);
        setTotalTax(0);
        setNetBuyPrice(0);
        setTotalAmount(0);
        setReOrderPoint(0);
        setLeadTime(0);
        setSellPriceWarning("");
        setSellPriceApplicableDiscountAmount(0);
        setSellPriceDiscount(0);
        setIsFixedSellPriceDiscount(true);
    };

    // Functino to retrive product details on edit
    const retriveProducts = async (productId) => {
        if (productId != 0) {
            const productData = await getProductDetailById(productId);

            setProductName(productData?.productName);
            setBrandName(productData?.brand);
            setBatchNo(productData?.batchNumber);
            setupcCode(productData?.upC_Code);
            setSKU(productData?.sku);
            setQuantity(productData?.quantity || 0);
            setUnusedQuantity(productData?.un_used_quantity || 0);

            if (productData?.measure) {
                measureOptions.forEach((option) => {
                    if (option.value === Number(productData?.measure)) {
                        setmeasure(option);
                    }
                });
            }
            setTabletCount(productData?.measureTabletCount || 0);

            if (productData?.discountPercentage) {
                setIsFixedDiscount(false);
                setDiscount(productData?.discountPercentage || 0);
            } else if (productData?.discountFixed) {
                setIsFixedDiscount(true);
                setDiscount(productData?.discountFixed || 0);
            } else {
                setIsFixedDiscount(true);
                setDiscount(0);
            }
            setmrp(productData?.maximumRetailPrice);

            // formating expiry date
            const tempExpDateValue = new Date(productData?.expiryDate);
            const formattedExpDate = tempExpDateValue
                .toISOString()
                .split("T")[0];
            setExpiryDate(formattedExpDate);

            setDescription(productData?.productDescription);
            setUploadedImageName(productData?.productImageName || null);
            setapplicableDiscount(productData?.applicableDiscountAmount || 0);
            setbuyPrice(productData?.basePrice || 0);
            setCGST(productData?.cgstRate || 0);
            setSGST(productData?.sgstRate || 0);
            setGST(productData?.gstRate || 0);
            setTotalTax(productData?.taxAmount || 0);
            setNetBuyPrice(productData?.netPrice || 0);
            setReOrderPoint(productData?.reorder_point || 0);
            setLeadTime(productData?.lead_time || 0);

            if (productData?.sellPriceDiscountFixed) {
                setIsFixedSellPriceDiscount(true);
                setSellPriceDiscount(productData?.sellPriceDiscountFixed || 0);
            } else {
                setIsFixedSellPriceDiscount(false);
                setSellPriceDiscount(
                    productData?.sellPriceDiscountPercentage || 0
                );
            }
            setSellPrice(
                (productData?.sellPrice + productData?.taxAmount).toFixed(2) ||
                    0
            );
            setSellPriceApplicableDiscountAmount(
                productData?.sellPriceApplicableDiscountAmount || 0
            );

            setInventoryValidate({
                category: "has-success",
                subCategory: "has-success",
                productName: "has-success",
                quantity: "has-success",
                mrp: "has-success",
                cgst: "has-success",
                sgst: "has-success",
                gst: "has-success",
                expiryDate: "has-success",
            });

            setFetchedCategories({
                category: productData?.categoryID,
                subCategory: productData?.subCategoryId,
            });

            // Setting state to check if data is retrived or not, for calculating taxes
            setIsDataRetrieved(true);
        }
    };

    const setProductCategoryStateFromId = (categoryID) => {
        const matchedCategory = category.find(
            (cat) => cat.value === categoryID
        );
        if (matchedCategory) {
            setCategorySelect(matchedCategory);
            setSubCategory(null);
            let error = { ...inventoryValidate };
            error.category = "has-success";
            setInventoryValidate(error);
        }
    };

    const setProductSubCategoryStateFromId = (subCategoryID) => {
        const matchedSubCategory =
            subCategory?.length > 0
                ? subCategory.find((subCat) => subCat.value === subCategoryID)
                : null;
        if (matchedSubCategory) {
            setSubCategorySelect(matchedSubCategory);
            let error = { ...inventoryValidate };
            error.subCategory = "has-success";
            setInventoryValidate(error);
        }
    };

    // For setting react select deafult value state after retrieving data for modification (Start) -------------
    useEffect(() => {
        setProductCategoryStateFromId(
            fetchedCategories.category,
            fetchedCategories.subCategory
        );
    }, [fetchedCategories]);

    useEffect(() => {
        if (
            fetchedCategories?.category &&
            fetchedCategories?.subCategory &&
            isDataRetrieved
        ) {
            setProductSubCategoryStateFromId(fetchedCategories.subCategory);
        }
    }, [subCategory]);
    // For setting react select deafult value state after retrieving data for modification (End) -------------

    useEffect(() => {
        getCategory();
    }, []);

    useEffect(() => {
        if (mrp !== null) {
            if (mrp) {
                setSellPrice(mrp);
            }
            calculateSellPriceDiscount();
        }
    }, [mrp, isFixedSellPriceDiscount, sellPriceDiscount]);

    useEffect(() => {
        if (categorySelect !== null) {
            getSubCategory(categorySelect);
        }
    }, [categorySelect]);

    useEffect(() => {
        if (location.state && location.state.productId && !isDataRetrieved) {
            setProductId(location.state.productId);
            retriveProducts(location.state.productId);
        } else {
            calculateTaxAndDiscount();
        }
    }, [location.state, isDataRetrieved]);

    return (
        <div className="content-dashboard-inventory">
            <div className="title-route-container">
                <h4 className="title">Inventory / Add Products</h4>
                <CustomButton
                    label="Upload Product by File"
                    onClick={() =>
                        navigate(`/admin${URL_ADD_INVENTORY_BY_FILE}`)
                    }
                />
            </div>

            {loader ? (
                <PageLoader />
            ) : (
                <>
                    <Card>
                        <CardBody>
                            <div className="inventory-card-header">
                                <h5>General Information</h5>
                            </div>

                            <Row>
                                <Col xs="12" md="3">
                                    <FormGroup className="custom-input-container">
                                        <label>Product Name</label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Product Name..."
                                            valid={
                                                inventoryValidate?.productName ===
                                                "has-success"
                                            }
                                            invalid={
                                                inventoryValidate?.productName ===
                                                "has-danger"
                                            }
                                            value={productName}
                                            maxLength={25}
                                            onChange={(e) => {
                                                let error = {
                                                    ...inventoryValidate,
                                                };
                                                if (
                                                    !Common.verifyLength(
                                                        e.target.value,
                                                        1
                                                    )
                                                ) {
                                                    error.productName =
                                                        "has-danger";
                                                } else {
                                                    error.productName =
                                                        "has-success";
                                                }
                                                setInventoryValidate(error);
                                                // setProductName(e.target.value);
                                                handleInputChange(
                                                    e,
                                                    setProductName
                                                );
                                            }}
                                        />
                                        <FormFeedback>
                                            Product name is required.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="3">
                                    <FormGroup className="custom-input-container">
                                        <label>Brand</label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Brand..."
                                            value={brandName}
                                            maxLength={25}
                                            onChange={(e) => {
                                                // setBrandName(e.target.value);
                                                handleInputChange(
                                                    e,
                                                    setBrandName
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="3">
                                    <FormGroup className="customer-input-container">
                                        <label htmlFor="category">
                                            Product Category
                                        </label>
                                        <Input
                                            className="w-100"
                                            name="category"
                                            placeholder="Select Category"
                                            type="hidden"
                                            valid={
                                                inventoryValidate?.category ===
                                                "has-success"
                                            }
                                            invalid={
                                                inventoryValidate?.category ===
                                                "has-danger"
                                            }
                                        />
                                        <Select
                                            className="profile-custom-select payment-mode-select"
                                            placeholder="Select Category"
                                            name="category"
                                            value={categorySelect}
                                            options={category}
                                            onChange={(e) => {
                                                let error = {
                                                    ...inventoryValidate,
                                                };
                                                if (e.value == null) {
                                                    error.category =
                                                        "has-danger";
                                                } else {
                                                    error.category =
                                                        "has-success";
                                                }
                                                setInventoryValidate(error);
                                                setCategorySelect(e);
                                                handleCategorySelect(e);
                                            }}
                                        />
                                        <FormFeedback>
                                            Category is required.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="3">
                                    <FormGroup className="customer-input-container">
                                        <label htmlFor="suvCategory">
                                            Product Sub Category
                                        </label>
                                        <Input
                                            className="w-100"
                                            name="subCategory"
                                            placeholder="Select Category"
                                            type="hidden"
                                            valid={
                                                inventoryValidate?.subCategory ===
                                                "has-success"
                                            }
                                            invalid={
                                                inventoryValidate?.subCategory ===
                                                "has-danger"
                                            }
                                        />
                                        <Select
                                            className="profile-custom-select payment-mode-select"
                                            placeholder="Select Category"
                                            name="subcategory"
                                            value={subCategorySelect}
                                            options={subCategory}
                                            onChange={(e) => {
                                                let error = {
                                                    ...inventoryValidate,
                                                };
                                                if (e.value == null) {
                                                    error.subCategory =
                                                        "has-danger";
                                                } else {
                                                    error.subCategory =
                                                        "has-success";
                                                }
                                                setInventoryValidate(error);
                                                setSubCategorySelect(e);
                                            }}
                                        />
                                        <FormFeedback>
                                            Sub Category is required.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="12" md="3">
                                    <FormGroup className="custom-input-container">
                                        <label>Batch No.</label>

                                        <Input
                                            value={batchNo}
                                            maxLength={25}
                                            placeholder="Enter UPC Code"
                                            type="text"
                                            onChange={(e) => {
                                                // setupcCode(e.target.value);
                                                handleInputChange(
                                                    e,
                                                    setBatchNo
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="3">
                                    <FormGroup className="custom-input-container">
                                        <label>UPC Code</label>
                                        <Input
                                            value={upcCode}
                                            maxLength={25}
                                            placeholder="Enter UPC Code"
                                            type="text"
                                            onChange={(e) => {
                                                handleInputChange(
                                                    e,
                                                    setupcCode
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="3">
                                    <FormGroup className="custom-input-container">
                                        <label>SKU</label>
                                        <Input
                                            value={sku}
                                            maxLength={25}
                                            placeholder="Enter SKU"
                                            type="text"
                                            onChange={(e) => {
                                                // setSKU(e.target.value);
                                                handleInputChange(e, setSKU);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="2">
                                    <FormGroup className="customer-input-container">
                                        <label htmlFor="measure">Measure</label>
                                        <Input
                                            className="w-100"
                                            name="measure"
                                            placeholder="Select Measure"
                                            type="hidden"
                                        />
                                        <Select
                                            className="profile-custom-select payment-mode-select"
                                            placeholder="Select Measure"
                                            name="measure"
                                            value={measure}
                                            options={measureOptions}
                                            onChange={(e) => {
                                                setmeasure(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                {(measure.value === 1 ||
                                    measure.value === 3) && (
                                    <Col xs="12" md="1">
                                        <FormGroup className="custom-input-container">
                                            <label>Tablets</label>
                                            <Input
                                                value={tabletCount}
                                                maxLength={25}
                                                placeholder="Enter No. of Tablet"
                                                type="number"
                                                onChange={(e) => {
                                                    handleInputChange(
                                                        e,
                                                        setTabletCount
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>

                            <Row>
                                <Col xs="12" md="3">
                                    <FormGroup className="custom-input-container">
                                        <label>Product Description</label>
                                        <Input
                                            type="textarea"
                                            placeholder="Enter Product Description..."
                                            className="textarea"
                                            rows="8"
                                            value={description}
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="3">
                                    <FormGroup className="custom-input-container">
                                        <label>Inventory Image</label>
                                        <input
                                            accept="image/x-png,image/svg,image/jpeg,image/jpg"
                                            onChange={(e) => uploadProduct(e)}
                                            type="file"
                                            name="file"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="3">
                                    <div className="inventory-image-preview">
                                        <div className="inventory-img-container">
                                            {file ? (
                                                <>
                                                    <img
                                                        className="displayImg"
                                                        src={URL.createObjectURL(
                                                            file
                                                        )}
                                                        alt={file.name}
                                                    />
                                                    <IoMdCloseCircle
                                                        size={20}
                                                        className="remove-inventory-img-btn"
                                                        onClick={removeImage}
                                                    />
                                                </>
                                            ) : isDataRetrieved &&
                                              uploadedImageName ? (
                                                <img
                                                    className="displayImg"
                                                    src={`https://erpinvociehtmlqueue.blob.core.windows.net/erp-inventory-images/${uploadedImageName}`}
                                                    alt={fileName}
                                                />
                                            ) : (
                                                <img
                                                    className="displayImg"
                                                    src={`${ERP_CDN_IMAGE_URL}/placeholder.jpeg`}
                                                    onError={(e) => {
                                                        e.target.onerror = null; // Prevent infinite loop if logo also fails
                                                        e.target.src =
                                                            Placeholder;
                                                    }}
                                                    alt="nopic"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="mt-3">
                        <CardBody>
                            <div className="inventory-card-header">
                                <h5>Pricing & Stocks</h5>
                            </div>

                            <Row>
                                <Col xs="12" md="3">
                                    <FormGroup className="custom-input-container">
                                        <label>MRP*</label>
                                        <Input
                                            maxLength={10}
                                            type="number"
                                            min="0"
                                            onKeyDown={preventNegativeValues}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                            valid={
                                                inventoryValidate?.mrp ===
                                                "has-success"
                                            }
                                            invalid={
                                                inventoryValidate?.mrp ===
                                                "has-danger"
                                            }
                                            value={mrp}
                                            onBlur={() => {
                                                calculateTaxAndDiscount();
                                                calculateSellPriceDiscount();
                                            }}
                                            onChange={(e) => {
                                                let error = {
                                                    ...inventoryValidate,
                                                };
                                                if (
                                                    !Common.verifyLength(
                                                        e.target.value,
                                                        1
                                                    ) ||
                                                    e.target.value <= 0
                                                ) {
                                                    error.mrp = "has-danger";
                                                } else {
                                                    error.mrp = "has-success";
                                                }
                                                setInventoryValidate(error);
                                                setmrp(e.target.value);
                                                setSellPrice(e.target.value);
                                            }}
                                            placeholder="Enter MRP"
                                        />
                                        <FormFeedback>
                                            MRP in positive amount is required.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="3">
                                    <div className="invoice-form-control">
                                        <label>
                                            Discount{" "}
                                            {isFixedDiscount ? "(₹)" : "(%)"}
                                        </label>
                                        <div className="inventory-discount-control">
                                            <input
                                                className="manual-discount-input"
                                                type="number"
                                                min="0"
                                                maxLength={10}
                                                value={discount}
                                                placeholder="Enter Discount"
                                                onBlur={() =>
                                                    calculateTaxAndDiscount()
                                                }
                                                onChange={(e) =>
                                                    setDiscount(e.target.value)
                                                }
                                                onKeyDown={
                                                    preventNegativeValues
                                                }
                                                onPaste={(e) => {
                                                    e.preventDefault();
                                                    return false;
                                                }}
                                            />
                                            <Input
                                                id="discount"
                                                name="Discount"
                                                type="select"
                                                className="type-dropdown"
                                                style={{ width: "150px" }}
                                                value={
                                                    isFixedDiscount
                                                        ? "true"
                                                        : "false"
                                                }
                                                onChange={(e) => {
                                                    setIsFixedDiscount(
                                                        e.target.value ===
                                                            "true"
                                                    );
                                                }}
                                                onBlur={() =>
                                                    calculateTaxAndDiscount()
                                                }
                                            >
                                                <option value={true}>
                                                    Fixed
                                                </option>
                                                <option value={false}>
                                                    Percentage
                                                </option>
                                            </Input>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="12" md="3">
                                    <div className="inventory-read-only-values">
                                        <label>
                                            Applicable Discount{" "}
                                            {isFixedDiscount ? "(₹)" : "(%)"}
                                        </label>
                                        <div className="value-cell">
                                            {applicableDiscount}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Divider />

                            <Row>
                                <Col xs="6" md="2">
                                    <FormGroup className="custom-input-container">
                                        <label style={{ display: "flex" }}>
                                            CGST %
                                        </label>

                                        <Input
                                            name="cgst"
                                            valid={
                                                inventoryValidate?.cgst ===
                                                "has-success"
                                            }
                                            invalid={
                                                inventoryValidate?.cgst ===
                                                "has-danger"
                                            }
                                            value={cgst}
                                            onChange={(e) => {
                                                const value = e.target.value;

                                                // Regular expression to match numbers between 0 and 100 with up to 4 decimal places
                                                const regex =
                                                    /^(100(\.0{1,4})?|[0-9]{1,2}(\.[0-9]{0,4})?)$/;

                                                // Validate input
                                                let error = {
                                                    ...inventoryValidate,
                                                };
                                                if (
                                                    regex.test(value) ||
                                                    value === ""
                                                ) {
                                                    error.cgst = "has-success";
                                                    setCGST(value);
                                                } else {
                                                    error.cgst = "has-danger";
                                                }

                                                setInventoryValidate(error);
                                            }}
                                            onBlur={() =>
                                                calculateTaxAndDiscount()
                                            }
                                            placeholder="0"
                                            type="text" // Use text to allow decimal points
                                            maxLength={10}
                                            onKeyDown={(e) => {
                                                if (
                                                    e.key === "." &&
                                                    e.target.value.includes(".")
                                                ) {
                                                    e.preventDefault();
                                                }
                                                if (
                                                    !/[0-9.]/.test(e.key) &&
                                                    e.key !== "Backspace" &&
                                                    e.key !== "Tab"
                                                ) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="6" md="2">
                                    <div className="inventory-read-only-values">
                                        <label>CGST Amount (₹)</label>
                                        <div className="value-cell">
                                            {cgstAmount}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6" md="2">
                                    <FormGroup className="custom-input-container">
                                        <label style={{ display: "flex" }}>
                                            SGST %
                                        </label>

                                        <Input
                                            name="sgst"
                                            valid={
                                                inventoryValidate?.sgst ===
                                                "has-success"
                                            }
                                            invalid={
                                                inventoryValidate?.sgst ===
                                                "has-danger"
                                            }
                                            value={sgst}
                                            onChange={(e) => {
                                                const value = e.target.value;

                                                // Regular expression to match numbers between 0 and 100 with up to 4 decimal places
                                                const regex =
                                                    /^(100(\.0{1,4})?|[0-9]{1,2}(\.[0-9]{0,4})?)$/;

                                                let error = {
                                                    ...inventoryValidate,
                                                };

                                                if (
                                                    regex.test(value) ||
                                                    value === ""
                                                ) {
                                                    error.sgst = "has-success";
                                                    setSGST(value);
                                                } else {
                                                    error.sgst = "has-danger";
                                                }

                                                setInventoryValidate(error);
                                            }}
                                            onBlur={() =>
                                                calculateTaxAndDiscount()
                                            }
                                            placeholder="0"
                                            type="text"
                                            maxLength={10}
                                            onKeyDown={(e) => {
                                                if (
                                                    e.key === "." &&
                                                    e.target.value.includes(".")
                                                ) {
                                                    e.preventDefault();
                                                }
                                                if (
                                                    !/[0-9.]/.test(e.key) &&
                                                    e.key !== "Backspace" &&
                                                    e.key !== "Tab"
                                                ) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="6" md="2">
                                    <div className="inventory-read-only-values">
                                        <label>SGST Amount (₹)</label>
                                        <div className="value-cell">
                                            {sgstAmount}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6" md="2">
                                    <FormGroup className="custom-input-container">
                                        <label style={{ display: "flex" }}>
                                            IGST %
                                        </label>

                                        <Input
                                            name="gst"
                                            valid={
                                                inventoryValidate?.gst ===
                                                "has-success"
                                            }
                                            invalid={
                                                inventoryValidate?.gst ===
                                                "has-danger"
                                            }
                                            value={gst}
                                            onChange={(e) => {
                                                const value = e.target.value;

                                                // Regular expression to match numbers between 0 and 100 with up to 4 decimal places
                                                const regex =
                                                    /^(100(\.0{1,4})?|[0-9]{1,2}(\.[0-9]{0,4})?)$/;

                                                // Validate input
                                                let error = {
                                                    ...inventoryValidate,
                                                };
                                                if (
                                                    regex.test(value) ||
                                                    value === ""
                                                ) {
                                                    error.gst = "has-success";
                                                    setGST(value);
                                                } else {
                                                    error.gst = "has-danger";
                                                }

                                                setInventoryValidate(error);
                                            }}
                                            onBlur={() =>
                                                calculateTaxAndDiscount()
                                            }
                                            placeholder="0"
                                            type="text" // Use text to allow decimal points
                                            maxLength={10}
                                            onKeyDown={(e) => {
                                                if (
                                                    e.key === "." &&
                                                    e.target.value.includes(".")
                                                ) {
                                                    e.preventDefault();
                                                }
                                                if (
                                                    !/[0-9.]/.test(e.key) &&
                                                    e.key !== "Backspace" &&
                                                    e.key !== "Tab"
                                                ) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="6" md="2">
                                    <div className="inventory-read-only-values">
                                        <label>IGST Amount (₹)</label>
                                        <div className="value-cell">
                                            {gstAmount}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Divider />

                            <Row>
                                <Col xs="6" md="2">
                                    <div className="inventory-read-only-values">
                                        <label>Buy Price (* without tax)</label>
                                        <div className="value-cell">
                                            {buyPrice}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6" md="2">
                                    <div className="inventory-read-only-values">
                                        <label>Total Tax</label>
                                        <div className="value-cell">
                                            {totalTax}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6" md="2">
                                    <div className="inventory-read-only-values">
                                        <label>Net Buy Price</label>
                                        <div className="value-cell">
                                            {netBuyPrice}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6" md="2">
                                    <FormGroup className="custom-input-container">
                                        <label>Stock (Quantity)*</label>
                                        <Input
                                            type="number"
                                            min="0"
                                            maxLength={10}
                                            onKeyDown={(e) => {
                                                if (
                                                    [
                                                        "e",
                                                        "E",
                                                        "+",
                                                        "-",
                                                        ".",
                                                        ",",
                                                    ].includes(e.key)
                                                ) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                            name="quantity"
                                            placeholder="Enter Quantity"
                                            valid={
                                                inventoryValidate?.quantity ===
                                                "has-success"
                                            }
                                            invalid={
                                                inventoryValidate?.quantity ===
                                                "has-danger"
                                            }
                                            value={quantity}
                                            onBlur={() =>
                                                calculateTaxAndDiscount()
                                            }
                                            onChange={(e) => {
                                                let error = {
                                                    ...inventoryValidate,
                                                };
                                                if (
                                                    !Common.verifyLength(
                                                        e.target.value,
                                                        1
                                                    ) ||
                                                    e.target.value < 0
                                                ) {
                                                    error.quantity =
                                                        "has-danger";
                                                } else {
                                                    error.quantity =
                                                        "has-success";
                                                }
                                                setInventoryValidate(error);
                                                setQuantity(e.target.value);
                                            }}
                                        />
                                        <FormFeedback>
                                            Quantity in positive amount is
                                            required.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                {unUseduantity > 0 && (
                                    <Col xs="6" md="2">
                                        <div className="inventory-read-only-values">
                                            <label>Unused Quantity</label>
                                            <div className="value-cell">
                                                {unUseduantity}
                                            </div>
                                        </div>
                                    </Col>
                                )}
                                <Col xs="6" md="2">
                                    <div className="inventory-read-only-values">
                                        <label>Total Amount</label>
                                        <div className="value-cell">
                                            {totalAmount}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Divider />

                            <Row>
                                <Col xs="12" md="3">
                                    <div className="invoice-form-control">
                                        <label>
                                            Sell Price Discount{" "}
                                            {isFixedSellPriceDiscount
                                                ? "(₹)"
                                                : "(%)"}
                                        </label>
                                        <div className="inventory-discount-control">
                                            <input
                                                className="manual-discount-input"
                                                type="number"
                                                min="0"
                                                maxLength={10}
                                                value={sellPriceDiscount}
                                                placeholder="Enter Discount"
                                                onChange={(e) => {
                                                    const value =
                                                        e.target.value;

                                                    if (value > 100) {
                                                        return;
                                                    }
                                                    setSellPriceDiscount(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={() =>
                                                    calculateSellPriceDiscount()
                                                }
                                                onKeyDown={
                                                    preventNegativeValues
                                                }
                                                onPaste={(e) => {
                                                    e.preventDefault();
                                                    return false;
                                                }}
                                            />
                                            <Input
                                                id="discount"
                                                name="SellPriceDiscount"
                                                type="select"
                                                className="type-dropdown"
                                                style={{ width: "150px" }}
                                                value={
                                                    isFixedSellPriceDiscount
                                                        ? "true"
                                                        : "false"
                                                }
                                                onChange={(e) => {
                                                    setIsFixedSellPriceDiscount(
                                                        e.target.value ===
                                                            "true"
                                                    );
                                                }}
                                                onBlur={() =>
                                                    calculateSellPriceDiscount()
                                                }
                                            >
                                                <option value={true}>
                                                    Fixed
                                                </option>
                                                <option value={false}>
                                                    Percentage
                                                </option>
                                            </Input>
                                        </div>
                                    </div>
                                </Col>

                                {/* Sell Price will be editbale, default = Buy Price */}
                                <Col xs="12" md="3">
                                    <FormGroup className="custom-input-container">
                                        <label>Sell Price</label>
                                        <Input
                                            value={sellPrice}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setSellPrice(value);
                                                setSellPriceWarning("");

                                                const sellPriceNum =
                                                    parseFloat(value);
                                                const basePriceNum =
                                                    parseFloat(buyPrice);
                                                const netPriceNum =
                                                    parseFloat(netBuyPrice);

                                                if (
                                                    sellPriceNum ===
                                                    basePriceNum
                                                ) {
                                                    setSellPriceWarning("");
                                                } else if (
                                                    sellPriceNum > basePriceNum
                                                ) {
                                                    // Sell price is greater than base price
                                                    setSellPriceWarning(
                                                        "Warning: Sell Price should not exceed Base Price."
                                                    );
                                                } else if (
                                                    sellPriceNum < netPriceNum
                                                ) {
                                                    // Sell price is less than net price
                                                    setSellPriceWarning(
                                                        "Warning: Sell Price is less than Net Price, which may result in a loss."
                                                    );
                                                }
                                            }}
                                            placeholder="0"
                                            type="number"
                                            min="0"
                                            maxLength={10}
                                            onKeyDown={preventNegativeValues}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                        />
                                    </FormGroup>
                                    {sellPriceWarning && (
                                        <div className="invalid-feedback d-block text-warning">
                                            {sellPriceWarning}
                                        </div>
                                    )}
                                </Col>

                                <Col xs="12" md="3">
                                    <div className="inventory-read-only-values">
                                        <label>Sell Price (*without tax)</label>
                                        <div className="value-cell">
                                            {(sellPrice - gstAmount - cgstAmount - sgstAmount).toFixed(2)}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="mt-3">
                        <CardBody>
                            <div className="inventory-card-header">
                                <h5>Other Details</h5>
                            </div>

                            <Row>
                                <Col xs="12" md="4">
                                    <FormGroup className="custom-input-container">
                                        <label style={{ display: "flex" }}>
                                            Reorder Point
                                        </label>
                                        <Input
                                            value={reOrderPoint}
                                            onChange={(e) => {
                                                setReOrderPoint(e.target.value);
                                            }}
                                            placeholder="0"
                                            type="number"
                                            min="0"
                                            maxLength={10}
                                            onKeyDown={preventNegativeValues}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                        />
                                        <FormFeedback>
                                            Reorder Point must be less than
                                            Quantity
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>

                                <Col xs="12" md="4">
                                    <FormGroup className="custom-input-container">
                                        <label>Lead Time (In Days)</label>

                                        <Input
                                            value={leadTime}
                                            onChange={(e) =>
                                                setLeadTime(e.target.value)
                                            }
                                            placeholder="0"
                                            type="number"
                                            min="0"
                                            maxLength={10}
                                            onKeyDown={preventNegativeValues}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col xs="12" md="4">
                                    <FormGroup className="custom-input-container">
                                        <label>Expiry Date</label>
                                        <Input
                                            id="exampleDate"
                                            name="date"
                                            placeholder="Enter Expiry Date"
                                            min={today}
                                            max="9999-12-31"
                                            type="date"
                                            value={expiryDate}
                                            onChange={(e) => {
                                                setExpiryDate(e.target.value);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <div className="">
                        <CustomButton
                            label={productId ? "Update Product" : "Add Product"}
                            type="submit"
                            onClick={handleSubmit}
                            style={{
                                backgroundColor: "#207841",
                                marginTop: "20px",
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default AddProduct;

import CustomButton from 'components/Button/CustomButton'
import ChildLoader from 'components/Loader/ChildLoader'
import { URL_FORECASTING_DEMAND_REPORT } from 'helpers/Paths'
import { URL_FORECASTING_TIME_DEMAND_REPORT } from 'helpers/Paths'
import { useReportStore } from 'hooks/Report'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody, Col, Row } from 'reactstrap'
import CompanyProfileRepository from 'repository/CompanyProfileRepository'
import { isArray, isEmpty } from 'underscore'
import FilterButton from 'views/Report/FilterButton'

const ForecastReport = () => {
    let initialReportState = {
        labels: [],
        datasets: [],
    };
    const [{ }, { getWeeklyDemandForecastData }, { }] = useReportStore();
    const [loader, setLoader] = useState(false);
    const [salesQuantity, setSaleQuantity] = useState([]);
    const [labels, setLabels] = useState([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const [loadingFilter, setLoadingFilter] = useState(false);
    const [chartData, setChartData] = useState(initialReportState);
    const [selectedDate, setSelectedDate] = useState('');

    const navigate = useNavigate();

    const barChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        return context[0].label;
                    },
                    label: function (context) {
                        return `Quantity: ${context.raw}`;
                    }
                },
                displayColors: false,
                backgroundColor: '#585858',
                titleColor: '#ffffff',
                bodyColor: '#ffffff',
                padding: 10,
                cornerRadius: 4,
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    borderDash: [5, 5],
                }
            },
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    padding: 20,
                    color: "#9f9f9f",
                    maxRotation: 0,
                    minRotation: 0,
                    callback: function (value) {
                        const label = this.getLabelForValue(value);
                        const words = label.split(' ');
                        const maxLineLength = 10;
                        let line = '';
                        let lines = [];
                        words.forEach(word => {
                            if ((line + word).length > maxLineLength) {
                                lines.push(line.trim());
                                line = '';
                            }
                            line += word + ' ';
                        });
                        lines.push(line.trim());
                        return lines;
                    },
                },
            }
        },
    };

    const lineChartOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        return context[0].label;
                    },
                    label: function (context) {
                        return `Revenue: ${context.raw}`;
                    }
                },
                displayColors: false,
                backgroundColor: '#585858',
                titleColor: '#ffffff',
                bodyColor: '#ffffff',
                padding: 10,
                cornerRadius: 4
            }
        },
        scales: {
            y: {
                min: 0,
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    padding: 20,
                    color: "#9f9f9f",
                    font: {
                        family: 'DM Sans, sans-serif'
                    },
                    stepSize: 500
                },
            },
            x: {
                grid: {
                    borderDash: [5, 5],
                },
                ticks: {
                    padding: 20,
                    color: "#9f9f9f",
                    maxRotation: 0,
                    minRotation: 0,
                    callback: function (value) {
                        const label = this.getLabelForValue(value);
                        const words = label.split(' ');
                        const maxLineLength = 10;
                        let line = '';
                        let lines = [];
                        words.forEach(word => {
                            if ((line + word).length > maxLineLength) {
                                lines.push(line.trim());
                                line = '';
                            }
                            line += word + ' ';
                        });
                        lines.push(line.trim());
                        return lines;
                    },
                },
            }
        },
    }

    const setChartQuantityDetails = (
        labelData,
        salesDataInfo,
        label = "Forecasted Sales Quantity"
    ) => {
        const maxValue = Math.max(...salesDataInfo);

        const chartQuantityDetails = {
            labels: labelData,
            datasets: [
                {
                    label: label,
                    data: salesDataInfo,
                    backgroundColor: salesDataInfo.map(value =>
                        value === maxValue ? '#3146F5' : '#B5BCFB'
                    ),
                    borderRadius: 10,
                    maxBarThickness: 50
                },
            ],
        };
        setChartData(chartQuantityDetails);
    };

    async function getReport(Date = "", endDate = "", page = 1) {
        setSelectedDate({ fromDate: Date, toDate: endDate })
    }

    useEffect(() => {
        setLoader(true);
        if (selectedDate) {
            getReportData();
        }
    }, [selectedDate]);

    async function getReportData(page = 1) {

        let params = {};
        params.pagenumber = page;
        params.pagesize = new CompanyProfileRepository().getPageSize();
        params.from_date = selectedDate.fromDate;
        params.to_date = selectedDate.toDate;

        let weeklyForecastData = await getWeeklyDemandForecastData(params);

        let arr = [];
        let labelsData = [];
        let forecastedSalesList = [];

        if (
            weeklyForecastData &&
            isArray(weeklyForecastData) &&
            !isEmpty(weeklyForecastData)
        ) {
            weeklyForecastData.map((prop, key) => {
                let createDateObj = moment(prop.createdDate);
                let formattedDate = createDateObj.format("DD MMMM YYYY");
                let formattedTime = createDateObj.format("hh:mm A");

                let formattedDateTime = `${formattedDate}, ${formattedTime}`;

                let obj = {
                    id: prop.productID,
                    productName: prop.productName,
                    forecastedSales: prop.weeklyForecastedSales,
                    reorderStatus: prop.reorderStatus,
                    dateTime: formattedDateTime,
                };
                arr.push(obj);
                labelsData.push(prop.productName);
                forecastedSalesList.push(prop.weeklyForecastedSales);
            });
        }

        if (page > 1) {

            const labelsInfo = [...labels, ...labelsData];
            const salesinfo = [...salesQuantity, ...forecastedSalesList];

            setSaleQuantity((prevData) => [...prevData, ...forecastedSalesList]);
            setLabels((prevData) => [...prevData, ...labelsData]);

            if (salesinfo.length > MAX_RECORDS) {
                let combinedData = labelsInfo.map((label, index) => ({
                    label,
                    forecastedSalesQuantity: salesinfo[index],
                }));

                combinedData.sort((a, b) => b.salesQuantity - a.salesQuantity);
                combinedData = combinedData.slice(0, MAX_RECORDS);

                let topLabels = combinedData.map((data) => data.label);
                let topForecastedSalesQuantities = combinedData.map(
                    (data) => data.forecastedSalesQuantity
                );

                setChartQuantityDetails(
                    topLabels,
                    topForecastedSalesQuantities,
                    `Top ${MAX_RECORDS} Forecasted Sales Quantities`
                );
                setLoadingMore(false);
                setLoader(false);
            } else {
                const allSalesQuantity = [...salesQuantity, ...forecastedSalesList];

                const allLabelsInfo = [...labels, ...labelsData];
                setChartQuantityDetails(allLabelsInfo, allSalesQuantity);
                setLoadingMore(false);
                setLoader(false);
            }
        } else {
            setSaleQuantity(forecastedSalesList);
            setLabels(labelsData);
            setChartQuantityDetails(labelsData, forecastedSalesList);
            setLoadingMore(false);
            setLoader(false);
        }
    }

    return (
        <>
            <>
                <div className="content-dashboard-report">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="title">Reports / Forecast</h4>
                        <div className="filter-wrapper">
                            <FilterButton
                                getReport={getReport}
                                setLoadingFilter={setLoadingFilter}
                            />
                        </div>
                    </div>

                    <Row className='gap-3'>
                        <Col sm="12" md="12" lg="12" xl="12">
                            <Row className="cards-container">
                                <Col sm="12" md="12" lg="6" xl="6">
                                    <Card className="chart-card">
                                        <CardBody>
                                            {loadingMore ? (
                                                <ChildLoader />
                                            ) : (
                                                <>
                                                    <div className="selling-header">
                                                        <h5>Revenue per product Forecast</h5>
                                                        <CustomButton
                                                            label="Detailed View"
                                                            onClick={() => navigate(`/admin${URL_FORECASTING_DEMAND_REPORT}`)}
                                                        />
                                                    </div>
                                                    <div className="bar-chart-wrapper">
                                                        <div className="bar-chart-container">
                                                            <Line
                                                                data={chartData}
                                                                options={lineChartOptions}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col sm="12" md="12" lg="6" xl="6">
                                    <Card className="chart-card">
                                        <CardBody>
                                            {loadingMore ? (
                                                <ChildLoader />
                                            ) : (
                                                <>
                                                    <div className="selling-header">
                                                        <h5>Quantity per product Forecast</h5>
                                                        <CustomButton
                                                            label="Detailed View"
                                                            onClick={() => navigate(`/admin${URL_FORECASTING_TIME_DEMAND_REPORT}`)}
                                                        />
                                                    </div>
                                                    <div className="bar-chart-wrapper">
                                                        <div className="bar-chart-container">
                                                            <Bar
                                                                data={chartData}
                                                                options={barChartOptions}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm="12" md="12" lg="12" xl="12" className='mt-3'>
                            <Row className="cards-container">
                                <Col sm="12" md="12" lg="6" xl="6">
                                    <Card className="chart-card">
                                        <CardBody>
                                            {loadingMore ? (
                                                <ChildLoader />
                                            ) : (
                                                <>
                                                    <div className="selling-header">
                                                        <h5>Revenue Forecast Report</h5>
                                                        <CustomButton
                                                            label="Detailed View"
                                                            onClick={() => navigate(`/admin${URL_FORECASTING_DEMAND_REPORT}`)}
                                                        />
                                                    </div>
                                                    <div className="bar-chart-wrapper">
                                                        <div className="bar-chart-container">
                                                            <Line
                                                                data={chartData}
                                                                options={lineChartOptions}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </>
        </>
    )
}

export default ForecastReport
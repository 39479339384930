import moment from "moment";
import CryptoJS from "crypto-js";

class Common {
  static UUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0]) &
        (15 >> (c / 4))
      ).toString(16)
    );
  }

  /**
   * Function for format date by sending date and format
   * Author: Devanshi Patel
   */

  static getFormatedDate(date, format, isUTC = false) {
    if (!isUTC) {
      return moment(date).format(format);
    } else {
      return moment.utc(date).local().format(format)
      // return moment.utc(utcDate).local().format(format);
    }
  }

  // static getFormatedDate(date, format, isUTC = false) {
  //   // Explicitly parse the provided date format
  //   const specificFormat = "DD MMMM YYYY, hh:mm A";
  //   let momentDate = moment(date, specificFormat, true);

  //   // If parsing fails, try other formats
  //   if (!momentDate.isValid()) {
  //     const formats = [
  //       moment.ISO_8601,
  //       "DD-MMMM-YYYY HH:mm:ss",
  //       "YYYY-MM-DDTHH:mm:ss.SSSZ", // Another common ISO format
  //       "YYYY-MM-DD HH:mm:ss", // Common format without T
  //       "MMMM D, YYYY, hh:mm A", // New format to handle "June 13, 2024, 12:00 AM"
  //       "YYYY-MM-DDTHH:mm:ss.SSS", // ISO 8601 with milliseconds
  //       "YYYY-MM-DDTHH:mm:ss",     // ISO 8601 without milliseconds
  //       "YYYY-MM-DD HH:mm:ss.SSS", // ISO-like format with milliseconds and space instead of T
  //       "YYYY-MM-DD HH:mm:ss",     // ISO-like format without milliseconds and space instead of T
  //     ];
  //     for (let fmt of formats) {
  //       momentDate = moment(date, fmt, true);
  //       if (momentDate.isValid()) break; // Stop at the first valid format
  //     }
  //   }

  //   // Final fallback to creating a new date if parsing fails
  //   if (!momentDate.isValid()) {
  //     return "Invalid Date"; // or any fallback display text
  //   }

  //   // Format the date based on the isUTC flag
  //   if (!isUTC) {
  //     return momentDate.utc().format(format); // Keep in UTC
  //   } else {
  //     return momentDate.local().format(format); // Convert to local time
  //   }
  // }

  static getJwtToken() {
    return localStorage.getItem("jwtToken")
      ? localStorage.getItem("jwtToken")
      : "";
  }

  static getLoginId() {
    return localStorage.getItem("loginId")
      ? localStorage.getItem("loginId")
      : "";
  }

  static getCommonKey() {
    return localStorage.getItem("commonKey")
      ? localStorage.getItem("commonKey")
      : "";
  }

  static getCompanyId() {
    return localStorage.getItem("companyId")
      ? localStorage.getItem("companyId")
      : "";
  }

  static getUserId() {
    return localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : "";
  }

  static getGstIn() {
    return localStorage.getItem("gstin")
      ? localStorage.getItem("gstin")
      : "";
  }

  static getCompanyName() {
    return localStorage.getItem("companyName")
      ? localStorage.getItem("companyName")
      : "";
  }

  static getCompanyAddress() {
    return localStorage.getItem("companyAddress")
      ? localStorage.getItem("companyAddress")
      : "";
  }
  static getCompanyPhone() {
    return localStorage.getItem("companyPhone")
      ? localStorage.getItem("companyPhone")
      : "";
  }
  static getCompanyEmail() {
    return localStorage.getItem("companyEmail")
      ? localStorage.getItem("companyEmail")
      : "";
  }

  static getRoleId() {
    return localStorage.getItem("roleId") ? localStorage.getItem("roleId") : "";
  }

  static getCompanyProfileSetting() {
    return localStorage.getItem("companyProfile")
      ? JSON.parse(localStorage.getItem("companyProfile"))
      : "";
  }

  /**
   * Function to get value from array of objects using key having specific value
   * Author: Devanshi Patel
   */
  static getValueByKeyWithSpecificValueFromKey = (array, key, value) => {
    const matchingObjects = array.filter((obj) => obj[key] === value);
    return matchingObjects.map((obj) => obj["feature_value"]);
  };

  /**
   * function that verifies if a string has a given length or not
   * Author: Devanshi Patel
   */
  static verifyLength = (value, minLength = 0, maxLength = 0) => {
    if (minLength > 0 && maxLength > 0) {
      return value.length >= minLength && value.length <= maxLength
        ? true
        : false;
    } else if (minLength > 0) {
      return value.length >= minLength ? true : false;
    } else if (maxLength > 0) {
      return value.length <= maxLength ? true : false;
    } else {
      return false;
    }
  };

  /**
   * function that verifies if a string has a valid email or not for single and multiple emails with semocolon seprator
   * Author: Devanshi Patel
   */
  static verifyEmail = (value) => {
    var emailRex =
      /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  /**
   * function that verifies if a string has a valid UPI ID or not
   * Author: Devanshi Patel
   */
  static verifyUPIId = (value) => {
    const upiIdRegex = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/;
    if (upiIdRegex.test(value)) {
      return true;
    }
    return false;
  };

  /**
   * function that verifies if a string has a same vale
   * Author: Devanshi Patel
   */
  static verifySameValue = (value, comparedValue) => {
    if (comparedValue === value) {
      return true;
    }
    return false;
  };

  /**
   * Function for getting today date
   * Author: Devanshi Patel
   */
  static getTodayDate = () => {
    return moment().format("YYYY-MM-DD");
  };

  /**
   * Function for getting before 1 month date
   * Author: Devanshi Patel
   */
  static getLastMonthDate = () => {
    return moment().subtract(1, "months").format("YYYY-MM-DD");
  };

  /**
   * Function for check and disable future date
   * Author: Devanshi Patel
   */
  static disableFutureDate = (currentDate) => {
    return currentDate.isBefore(moment());
  };

  /**
   * Function for encrypt query param
   * Author: Devanshi Patel
   */
  static encryptQueryParam = (param) => {
    // console.log(param);
    const ciphertext = CryptoJS.AES.encrypt(
      param,
      "MYALGOSOFTERPDEV"
    ).toString();
    return ciphertext;
  };

  /**
   * Function for decrypt query param
   * Author: Devanshi Patel
   */
  static decryptQueryParam = (encryptedParam) => {
    const bytes = CryptoJS.AES.decrypt(encryptedParam, "MYALGOSOFTERPDEV");
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };

  /**
   * Function for // geolocationUtil to gt device location
   * Author: Shivam Rayjada
   */

  static fetchGeolocation = async () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            try {
              const response = await fetch(
                `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
              );
              const data = await response.json();
              const { city, principalSubdivision, countryName } = data;

              // Construct location information
              let locationInfo = "";
              if (city && (principalSubdivision || countryName)) {
                if (principalSubdivision) {
                  locationInfo = `${city}, ${principalSubdivision}, ${countryName}`;
                } else {
                  locationInfo = `${city}, ${countryName}`;
                }
              } else if (city) {
                locationInfo = `${city}`;
              } else if (principalSubdivision) {
                locationInfo = `${principalSubdivision}, ${countryName}`;
              } else {
                locationInfo = `${countryName}`;
              }

              resolve(locationInfo);
            } catch (error) {
              // console.error("Error fetching location:", error);
              reject(error);
            }
          },
          (error) => {
            // console.error("Error getting geolocation:", error);
            reject(error);
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  // Function to get browser information
  static getBrowserInfo = () => {
    const { userAgent } = navigator;
    let browserInfo = "Unknown Browser";

    if (userAgent.indexOf("Chrome") > -1) {
      browserInfo = `Chrome ${Common.getBrowserVersion(userAgent)}`;
    } else if (userAgent.indexOf("Firefox") > -1) {
      browserInfo = `Firefox ${Common.getBrowserVersion(userAgent)}`;
    } else if (userAgent.indexOf("Edge") > -1) {
      browserInfo = `Edge ${Common.getBrowserVersion(userAgent)}`;
    } else if (userAgent.indexOf("Safari") > -1) {
      browserInfo = `Safari ${Common.getBrowserVersion(userAgent)}`;
    }

    return browserInfo;
  };

  // Function to get browser version
  static getBrowserVersion = (userAgent) => {
    const match = userAgent.match(/(?:Chrome|Firefox|Edge|Safari)\/([\d.]+)/);
    return match ? match[1] : "Unknown";
  };

  // Function to get operating system information
  static getOSInfo = () => {
    const { userAgent, platform } = navigator;
    let osInfo = "Unknown OS";

    if (platform.indexOf("Win") !== -1) {
      if (userAgent.indexOf("Windows NT 10.0") !== -1) {
        osInfo = "Windows 10";
      } else if (userAgent.indexOf("Windows NT 10.0") !== -1) {
        osInfo = "Windows 10";
      } else if (userAgent.indexOf("Windows NT 6.1") !== -1) {
        osInfo = "Windows 7";
      } else if (userAgent.indexOf("Windows NT 6.2") !== -1) {
        osInfo = "Windows 8";
      } else if (userAgent.indexOf("Windows NT 6.3") !== -1) {
        osInfo = "Windows 8.1";
      } else if (userAgent.indexOf("Windows NT 10.0") !== -1) {
        osInfo = "Windows 10";
      } else if (userAgent.indexOf("Windows NT 11.0") !== -1) {
        osInfo = "Windows 11";
      } else {
        osInfo = "Unknown Windows";
      }
    } else if (platform.indexOf("Mac") !== -1) {
      osInfo = "Mac OS";
    } else if (platform.indexOf("Linux") !== -1) {
      osInfo = "Linux";
    } else if (platform.indexOf("Android") !== -1) {
      osInfo = "Android";
    } else if (platform.indexOf("iOS") !== -1) {
      osInfo = "iOS";
    }

    return osInfo;
  };
}

export default Common;

import React, { useRef } from "react";
import logo from "assets/img/new-logo-with-text-dark.svg";
import { Link, useNavigate } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import { AUTH_URL_DEMO_LOGIN } from "helpers/Paths";
import { AUTH_URL_SIGNUP } from "helpers/Paths";
import { AUTH_URL_LOGIN } from "helpers/Paths";

const Navbar = () => {
    const navbarRef = useRef(null);
    const logoRef = useRef(null);
    const demoButtonRef = useRef(null);
    const signInButtonRef = useRef(null);
    const createAccountButtonRef = useRef(null);
    const navigate = useNavigate();

    useGSAP(() => {
        const tl = gsap.timeline();

        tl.from(logoRef.current, {
            x: -50,
            opacity: 0,
            duration: 0.6,
        });

        // tl.from(
        //     [
        //         demoButtonRef.current,
        //         createAccountButtonRef.current,
        //         signInButtonRef.current,
        //     ],
        //     {
        //         x: 50,
        //         opacity: 1,
        //         stagger: 0.2,
        //         duration: 0.6,
        //     },
        //     "-=0.2"
        // );

        return () => {
            tl.kill();
        };
    }, []);

    return (
        <nav className="navbar-container" ref={navbarRef}>
            <div className="nav-logo-container" ref={logoRef}>
                <a ref={logoRef} href="https://apsthreeai.ai/" target="_blank">
                    <img
                        src={`${ERP_CDN_IMAGE_URL}/new-logo-with-text-dark.svg`}
                        onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop if logo also fails
                            e.target.src = logo;
                        }}
                        alt="APS THREEAI"
                    />
                </a>
            </div>

            <div className="nav-buttons">
                {/* <Link
          to={AUTH_URL_DEMO_LOGIN}
          ref={signInButtonRef}
          className="nav-btn nav-login-btn"
        >
          Demo Sign In
        </Link>
        <Link
          to="/auth/login"
          ref={signInButtonRef}
          className="nav-btn nav-login-btn"
        >
          Sign In
        </Link>
        <Link
          to="/auth/signup"
          ref={createAccountButtonRef}
          className="nav-btn nav-signin-btn"
        >
          Create an Account
        </Link> */}

                <button
                    ref={demoButtonRef}
                    className="nav-btn nav-signin-btn"
                    onClick={() => navigate(AUTH_URL_DEMO_LOGIN)}
                >
                    Demo Sign In
                </button>

                <button
                    ref={createAccountButtonRef}
                    className="nav-btn nav-signin-btn"
                    onClick={() => navigate(AUTH_URL_SIGNUP)}
                >
                    Create Account
                </button>

                <button
                    ref={signInButtonRef}
                    className="nav-btn nav-login-btn"
                    onClick={() => navigate(AUTH_URL_LOGIN)}
                >
                    Sign In
                </button>
            </div>
        </nav>
    );
};

export default Navbar;

// Sitemap.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
  URL_ACCOUNT_RECIEVABLE,
  URL_ACCOUNT_PAYABLE,
  URL_INVENTORYHISTORY,
  URL_HISTORICAL_DEMAND_REPORT,
  URL_INVENTORY_REPORT,
  URL_SUBSCRIPTION,
  URL_CONNECT_US,
  URL_ACTIVITY_LOGS,
  URL_COMPANY_PROFILE_V2,
  URL_CREATE_BILL,
  URL_Template,
  URL_INVENTORY_PRODUCTS_ADD,
  URL_INVENTORYDISCOUNTS,
  URL_NOTIFICATIONSCHEDULE,
  URL_BROADCAST_NOTIFICATION,
  URL_CUSTOMER_LIST,
  URL_NOTIFICATIONSCHEDULE_REPORT,
  URL_MANAGE_CUSTOMER,
  URL_ADD_INVENTORY_BY_FILE,
  URL_INVENTORY_PRODUCTS_LIST,
  URL_BILL_DASHBOARD,
  URL_SITEMAP,
  URL_FEEDBACK,
  URL_RAISE_ISSUE,
  URL_PlanDetails,
  URL_INVENTORY_EXPIRY_REPORT,
  URL_FORECAST_REPORT,
  URL_REORDER_STOCK_REPORT,
  URL_PAYMENT,
} from "helpers/Paths";
import { MdClear } from "react-icons/md"; // Importing the cancel icon
import { URL_BALANCESHEET_REPORT } from "helpers/Paths";

const Sitemap = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const allRoutes = [
    {
      title: "Bills",
      items: [
        { name: "Create Bill", path: "/admin" + URL_CREATE_BILL },
        { name: "Bills Report", path: "/admin" + URL_BILL_DASHBOARD },
        { name: "Combo Template (Offers)", path: "/admin" + URL_Template },
      ],
    },
    {
      title: "Inventory",
      items: [
        { name: "Products List", path: "/admin" + URL_INVENTORY_PRODUCTS_LIST },
        { name: "Add Products", path: "/admin" + URL_INVENTORY_PRODUCTS_ADD },
        {
          name: "Add Inventory By File",
          path: "/admin" + URL_ADD_INVENTORY_BY_FILE,
        },
        { name: "Discounts", path: "/admin" + URL_INVENTORYDISCOUNTS },
      ],
    },
    {
      title: "Customer",
      items: [
        { name: "Manage Customers", path: "/admin" + URL_MANAGE_CUSTOMER },
        {
          name: "Schedule Notification",
          path: "/admin" + URL_NOTIFICATIONSCHEDULE,
        },
        {
          name: "Notification Report",
          path: "/admin" + URL_NOTIFICATIONSCHEDULE_REPORT,
        },
        {
          name: "Broadcast Notification",
          path: "/admin" + URL_BROADCAST_NOTIFICATION,
        },
        { name: "Customer List", path: "/admin" + URL_CUSTOMER_LIST },
      ],
    },
    {
      title: "Payment",
      items: [
        { name: "Create Transaction", path: "/admin" + URL_PAYMENT },
        { name: "Account Payable", path: "/admin" + URL_ACCOUNT_PAYABLE },
        { name: "Account Receivable", path: "/admin" + URL_ACCOUNT_RECIEVABLE },
      ],
    },
    {
      title: "Analytics",
      items: [
        {
          name: "Balance Sheet Report",
          path: "/admin" + URL_BALANCESHEET_REPORT,
        },
        {
          name: "Inventory History Report",
          path: "/admin" + URL_INVENTORYHISTORY,
        },
        {
          name: "ReOrder Stock Report",
          path: "/admin" + URL_REORDER_STOCK_REPORT,
        },
        { name: "Sales Report", path: "/admin" + URL_HISTORICAL_DEMAND_REPORT },
        { name: "Forecast Report", path: "/admin" + URL_FORECAST_REPORT },
        { name: "Inventory Report", path: "/admin" + URL_INVENTORY_REPORT },
        {
          name: "Inventory Expiry Report",
          path: "/admin" + URL_INVENTORY_EXPIRY_REPORT,
        },
      ],
    },
    {
      title: "Subscription",
      items: [
        { name: "Subscription Details", path: "/admin" + URL_SUBSCRIPTION },
        { name: "Billing & Usage", path: "/admin" + URL_PlanDetails },
      ],
    },
    {
      title: "Help & Activity",
      items: [
        { name: "Company Profile", path: "/admin" + URL_COMPANY_PROFILE_V2 },
        { name: "Feedback", path: "/admin" + URL_FEEDBACK },
        { name: "Activity Logs", path: "/admin" + URL_ACTIVITY_LOGS },
        { name: "Raise Issue", path: "/admin" + URL_RAISE_ISSUE },
        { name: "Connect Us", path: "/admin" + URL_CONNECT_US },
        { name: "Sitemap", path: "/admin" + URL_SITEMAP },
      ],
    },
  ];

  const clearSearch = () => {
    setSearchTerm(""); // Clear the search term
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const highlightText = (text) => {
    if (!searchTerm) return text;

    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div className="content-dashboard-inventory">
      <div className="title-route-container">
        <h4 className="title">Sitemap</h4>
      </div>
      {/* <Card className="inventory-product-card">
        <CardBody> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            width: "60%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "-20px",
            justifyContent: "center",
          }}
        >
          {searchTerm && (
            <MdClear
              onClick={clearSearch}
              style={{
                cursor: "pointer",
                color: "#007bff",
                marginLeft: "10px",
              }}
              size={20}
            />
          )}
        </div>
      </div>

      <Row className="mr-1">
        {allRoutes.map((route, routeIndex) => (
          <Col
            xs="12"
            sm="6"
            md="4"
            key={routeIndex}
            style={{ marginBottom: "10px" }}
          >
            <Card
              style={{
                padding: "5px",
                border: "1px solid #d1d1d1",
                boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                backgroundColor: "#f9f9f9",
                height: "250px", // Fixed height for the card
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CardBody>
                <h3
                  style={{
                    fontWeight: "bold",
                    fontSize: 20,
                    marginBottom: "10px",
                  }}
                >
                  {route.title}
                </h3>
                <ul
                  style={{
                    listStyleType: "none",
                    paddingLeft: 0,
                    marginBottom: "0",
                  }}
                >
                  {route.items
                    .filter((item) =>
                      item.name.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                    .map((item, idx) => (
                      <li key={idx} style={{ marginBottom: "5px" }}>
                        <Link
                          to={item.path}
                          style={{
                            textDecoration: "none",
                            color: "#434CE6",
                            fontWeight: "600",
                            transition: "color 0.3s ease",
                          }}
                        >
                          {highlightText(item.name)}
                        </Link>
                      </li>
                    ))}
                </ul>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      {/* </CardBody>
      </Card> */}
    </div>
  );
};

export default Sitemap;

import CustomButton from "components/Button/CustomButton";
import ReactTable from "components/ReactTable/ReactTable.js";
import Common from "helpers/Common";
import { notificationMessages } from "helpers/constantMessages";
import { useAzureStore } from "hooks/Azure";
import { useInventoryStore } from "hooks/Inventory";
import { useLoginStore } from "hooks/Login";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaCheck, FaTrashCan } from "react-icons/fa6";
import { MdOutlineFileUpload } from "react-icons/md";
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import FINAL_STEP_IMG from "assets/img/icons/blue-right-tick-img.png";
import { URL_INVENTORY_PRODUCTS_LIST } from "helpers/Paths";
import { useNavigate } from "react-router-dom";
import PageLoader from "components/Loader/PageLoader";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px",
  borderWidth: 2,
  borderRadius: 20,
  borderColor: "#434CE6",
  borderStyle: "dashed",
  backgroundColor: "#F5F6FD",
  color: "#444444",
  margin: "20px 0 30px",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const AddInventoryByFile = () => {
  const Steps = [
    {
      step: 1,
      label: "File Upload",
    },
    {
      step: 2,
      label: "Preview",
    },
    {
      step: 3,
      label: "Submit",
    },
  ];

  const dt = useRef(null);
  const navigate = useNavigate();

  const [{}, {}, { setAlerts }] = useLoginStore();
  const [{}, { getFileContent }, { uploadBlobs, uploadInventoryByFile }] =
    useInventoryStore();
  const [{}, { fetchInventorySampleFromAzure }, {}] = useAzureStore();

  const [inventoryUploadColumn, setInventoryUploadColumn] = useState([]);
  const [inventoryUploadData, setInventoryUploadData] = useState([]);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadFileList, setUploadFileList] = useState([]);
  const [fileURL, setfileURL] = useState("");
  const [pdfFileURL, setpdffileURL] = useState("");

  const [loader, setLoader] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const fileNameRef = useRef("");

  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    setUploadFileList(acceptedFiles);
  }, []);

  const deleteFile = () => {
    setUploadFileList([]);
  };

  const uploadBlob = async (e) => {
    setLoader(true);
    e.preventDefault();

    const renamedAcceptedFiles = uploadFileList.map(
      (file) =>
        new File(
          [file],
          file.name.split(".")[0] +
            "_" +
            Common.getCompanyId() +
            "_" +
            Date.now() +
            "." +
            file.name.split(".")[1],
          { type: file.type }
        )
    );

    let formData = new FormData();
    formData.append("files", renamedAcceptedFiles[0]);

    let res = await uploadBlobs(formData);
    let fileName = renamedAcceptedFiles[0].name;
    setUploadFileName(fileName);

    // Store the fileName in the ref instead of setting 'current' on a string
    fileNameRef.current = fileName;

    if (res) {
      let fileData = await getFileContent({ fileName: fileName });

      if (
        fileData &&
        fileData.columns &&
        fileData.data &&
        fileData.data.length > 0
      ) {
        let fileColumns = fileData.columns.split(",");
        let columnArr = [];
        let dataArr = [];

        if (fileColumns && fileColumns.length > 0) {
          fileColumns.map((v) => {
            let columnObj = {};
            columnObj.Header = v;
            columnObj.accessor = v;
            columnArr.push(columnObj);
          });
        }

        fileData.data.map((p, key) => {
          let fileDatas = p.split(",");
          let dataObj = {
            id: key,
          };

          if (fileDatas && fileDatas.length > 0) {
            fileDatas.map((prop, i) => {
              if (columnArr[i]) {
                dataObj[columnArr[i].Header] = prop;
              }
            });
          }
          dataArr.push(dataObj);
        });

        setInventoryUploadColumn(columnArr);
        setInventoryUploadData(dataArr);
      }
    }

    setLoader(false);
    setCurrentStep(2);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
    setuploadPercentage,
  } = useDropzone({
    // onDrop,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections && fileRejections.length > 0) {
        fileRejections.forEach((file) => {
          file.errors.forEach(async (err) => {
            if (err.code === "file-invalid-type") {
              await setAlerts({ type: "danger", message: err.message });
            }

            if (err.code === "too-many-files") {
              await setAlerts({ type: "danger", message: err.message });
            }
          });
        });
      }

      if (acceptedFiles && acceptedFiles.length > 0) {
        onDrop(acceptedFiles);
      }
    },
    accept: {
      "text/csv": [".csv"],
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const getSampleInventory = async () => {
    let params = {
      isXls: true,
    };
    let URL = await fetchInventorySampleFromAzure(params);
    setfileURL(URL);
  };

  const getSamplePDFInventory = async () => {
    let params = {
      isPDf: true,
    };
    let URL = await fetchInventorySampleFromAzure(params);
    setpdffileURL(URL);
  };

  const downloadSampleFile = () => {
    window.location.href = fileURL;
  };

  const downloadSamplePDFFile = () => {
    window.open(pdfFileURL, "_blank");
  };

  const inventoryUpload = async () => {
    setLoader(true);
    let res = await uploadInventoryByFile({ fileName: fileNameRef.current });
    if (res) {
      await setAlerts({
        type: "success",
        message: notificationMessages.addInventorySuccess,
      });
      setCurrentStep(3);
      setLoader(false);
      // navigate(ADMIN_URL_INVENTORY_FILE_SUMMARY);
    }
    setLoader(false);
  };

  const resetInventoryUpload = () => {
    setInventoryUploadColumn([]);
    setInventoryUploadData([]);
    setUploadFileName("");
    fileNameRef.current = "";
    setUploadFileList([]);
    setCurrentStep(1);
  };

  useEffect(() => {
    getSampleInventory();
    getSamplePDFInventory();
  }, []);

  const handleKeyPress = (event) => {
    if (event.altKey && (event.key === "p" || event.key === "P")) {
      inventoryUpload(); // Trigger the proceed button action
    }
  };

  useEffect(() => {
    // Add the keydown event listener
    window.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <div className="content-dashboard-inventory">
          {/* <div className="d-flex align-items-center justify-content-between "> */}
          <div className="title-route-container">
            <h4 className="title">
              Inventory / Add Products / Add Inventory By File{" "}
            </h4>
          </div>

          <Card className="h-100">
            <CardBody>
              <div
                className="inventory-card-header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ display: "flex", gap: "10px", marginLeft: "auto" }}
                >
                  <CustomButton
                    label="Download Sample CSV"
                    onClick={downloadSampleFile}
                  />
                  <CustomButton
                    label="Download Sample PDF"
                    onClick={downloadSamplePDFFile}
                  />
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <div className="inventory-stepper-container">
                  {Steps?.map((step, i) => (
                    <div
                      key={i}
                      className={`inventory-step-item ${
                        currentStep === i + 1 && "inventory-active-step"
                      } ${
                        (i + 1 < currentStep || currentStep === 3) &&
                        "inventory-complete-step"
                      } `}
                    >
                      <div className="inventory-step">
                        {i + 1 < currentStep || currentStep === 3 ? (
                          <FaCheck size={24} />
                        ) : (
                          i + 1
                        )}
                      </div>
                      <p>{step.label}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="container">
                {currentStep === 1 && (
                  <>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <div
                          className="dropbox-content-container"
                          style={{ width: "80%", gap: "20px" }}
                        >
                          <MdOutlineFileUpload
                            size={80}
                            color="#434CE6"
                            className="dropbox-upload-icon"
                          />
                          <div className="">
                            <p className="file-drop-title">
                              Browse or "Drag and drop file"
                            </p>
                            <p className="file-drop-desc">
                              Supported formats: CSV, PDF
                            </p>
                            <em>(Only 1 file at a time you can drop here)</em>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="">
                      {uploadFileList &&
                        uploadFileList.length > 0 &&
                        inventoryUploadData &&
                        inventoryUploadData.length <= 0 &&
                        uploadFileList.map((file) => (
                          <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                              <div
                                className="w-100 d-flex align-items-center justify-content-between"
                                key={file.path}
                              >
                                <p className="m-0">
                                  {file.path} - {file.size} bytes
                                </p>
                                <FaTrashCan
                                  size={15}
                                  onClick={deleteFile}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ margin: "20px 0" }}
                              >
                                <CustomButton
                                  label={
                                    loader ? (
                                      <Spinner size="sm" color="light" />
                                    ) : (
                                      "Continue"
                                    )
                                  }
                                  onClick={(e) => uploadBlob(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                        ))}
                    </div>
                  </>
                )}

                {currentStep === 2 && (
                  <div className="">
                    {inventoryUploadData &&
                      inventoryUploadData.length > 0 &&
                      inventoryUploadColumn &&
                      inventoryUploadColumn.length > 0 && (
                        <div className="mt-4">
                          <div
                            className="title-div"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div className="title-left-div">
                              <h5>Data Preview</h5>
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              <CustomButton
                                label="Proceed to Upload CSV"
                                onClick={inventoryUpload}
                              />
                              <CustomButton
                                label="Cancel"
                                onClick={resetInventoryUpload}
                                style={{
                                  backgroundColor: "#555555",
                                  marginLeft: "10px",
                                }}
                              />
                            </div>
                          </div>

                          <DataTable
                            style={{ marginTop: "20px" }}
                            ref={dt}
                            size="large"
                            resizableColumns
                            columnResizeMode="expand"
                            stripedRows
                            value={inventoryUploadData ?? []}
                            dataKey="productId"
                            tableStyle={{ minWidth: "50rem" }}
                            pt={{ root: { className: "table" } }}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 15]}
                          >
                            {/* Dynamically render columns */}
                            {inventoryUploadData &&
                              inventoryUploadData.length > 0 &&
                              Object.keys(inventoryUploadData[0]).map(
                                (key, index) => (
                                  <Column
                                    key={index}
                                    field={key}
                                    header={key}
                                  ></Column>
                                )
                              )}
                          </DataTable>
                        </div>
                      )}
                  </div>
                )}

                {currentStep === 3 && (
                  <div className="w-100 d-flex flex-column justify-content-center align-items-center gap-3">
                    <img
                      src={FINAL_STEP_IMG}
                      alt="Product Added"
                      style={{ width: "100px", objectFit: "contain" }}
                    />
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "500",
                        color: "#747474",
                        marginTop: "20px",
                      }}
                    >
                      Product Added Successfully
                    </p>
                    <CustomButton
                      label="Back To Inventory"
                      onClick={() =>
                        navigate(`/admin${URL_INVENTORY_PRODUCTS_LIST}`)
                      }
                      style={{ margin: "20px 0", backgroundColor: "#555555" }}
                    />
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
};

export default AddInventoryByFile;

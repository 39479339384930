import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { Card, CardBody, Col, FormGroup, Row } from "reactstrap";
import ChildLoader from "components/Loader/ChildLoader";
import Common from "helpers/Common";
import { useInventoryStore } from "hooks/Inventory";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";
import { FaRegCircleCheck } from "react-icons/fa6";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import { isArray, isEmpty } from "underscore";
import DownloadReport from "views/Report/DownloadReport";
import FilterButton from "views/Report/FilterButton";
import { VscHistory } from "react-icons/vsc";

const InventoryReport = () => {
  const [{ }, { reorderStockReport }, { }] = useInventoryStore();
  const [debitReportData, setDebitReportData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [salesQuantity, setSaleQuantity] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [loadingFilter, setLoadingFilter] = useState(false);

  const MAX_RECORDS = 15;

  const options = {
    // responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            return `Quantity: ${context.raw}`;
          },
        },
        displayColors: false,
        backgroundColor: "#585858",
        titleColor: "#ffffff",
        bodyColor: "#ffffff",
        padding: 10,
        cornerRadius: 4,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 20,
          color: "#9f9f9f",
          maxRotation: 0,
          minRotation: 0,
          callback: function (value) {
            const label = this.getLabelForValue(value);
            const words = label.split(" ");
            const maxLineLength = 10;
            let line = "";
            let lines = [];
            words.forEach((word) => {
              if ((line + word).length > maxLineLength) {
                lines.push(line.trim());
                line = "";
              }
              line += word + " ";
            });
            lines.push(line.trim());
            return lines;
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          borderDash: [5, 5],
        },
      },
    },
  };

  let initialReportState = {
    labels: [],
    datasets: [],
  };

  const [chartData, setChartData] = useState(initialReportState);

  useEffect(() => {
    setLoader(true);
  }, []);

  const setChartQuantityDetails = (labelData, quantity, label = "Quantity") => {
    const maxValue = Math.max(...quantity);
    const chartDetails = {
      labels: labelData,
      datasets: [
        {
          label: label,
          data: quantity,
          backgroundColor: quantity.map((value) =>
            value === maxValue ? "#3146F5" : "#B5BCFB"
          ),
          borderRadius: 10,
          maxBarThickness: 50,
        },
      ],
    };
    setChartData(chartDetails);
  };

  async function getReport(Date = "", endDate = "", page = 1) {
    setSelectedDate({ fromDate: Date, toDate: endDate })
  }

  useEffect(() => {
    setLoader(true);
    if (selectedDate) {
      getReportData();
    }
  }, [selectedDate]);

  const getReportData = async (page = 1) => {

    let params = {
      pagenumber: page,
      pagesize: new CompanyProfileRepository().getPageSize(),
      from_date: selectedDate.fromDate,
      to_date: selectedDate.toDate
    };

    let debitData = await reorderStockReport(params);

    let arr = [];
    // let labels = [];
    // let quantity = [];

    let labelsData = [];
    let salesQuantityData = [];

    if (debitData && isArray(debitData) && !isEmpty(debitData)) {
      debitData.map((prop, key) => {
        let createDateObj = moment(prop.create_datetime);
        let date = createDateObj.format("DD-MM-YYYY");
        let formattedDateTime = Common.getFormatedDate(
          moment.utc(createDateObj),
          "MMM D, YYYY, hh:mm A",
          true
        );

        let obj = {
          availableQuantity: prop.availableQuantity,
          brand: prop.brand,
          category: prop.category,
          productId: prop.productId,
          productName: prop.productName,
          reorderQuantity: prop.reorderQuantity,
          reorderStatus: prop.reorderStatus,
          soldQuantity: prop.soldQuantity,
        };
        arr.push(obj);
        labelsData.push(prop.productName);
        salesQuantityData.push(prop.availableQuantity);
      });
    }

    if (page > 1) {
      // Append new data to existing data for subsequent pages
      setDebitReportData((prevData) => [...prevData, ...arr]);
      setLoadingMore(false);
      setLoader(false);
    } else {
      // Set new data for the first page
      setDebitReportData(arr);
      setLoadingMore(false);
      setLoader(false);
    }

    if (debitData.length === 0) {
      if (page === 1) {
        setSaleQuantity(salesQuantityData);

        setLabels(labelsData);

        setChartQuantityDetails(labelsData, salesQuantityData);
        setLoadingMore(false);
        setLoader(false);
      }
    } else if (debitData.length > 0 && page > 1) {
      // Append new data to existing data for subsequent pages

      const salesinfo = [...salesQuantity, ...salesQuantityData];
      const labelsInfo = [...labels, ...labelsData];

      setSaleQuantity((prevData) => [...prevData, ...salesQuantityData]);
      setLabels((prevData) => [...prevData, ...labelsData]);

      setDebitReportData((prevData) => [...prevData, ...arr]);

      if (salesinfo.length > MAX_RECORDS) {
        // Combine data for sorting and slicing
        let combinedData = labelsInfo.map((label, index) => ({
          label,
          salesQuantity: salesinfo[index],
        }));

        // Sort combinedData based on salesQuantity in descending order and slice top 20 records
        combinedData.sort((a, b) => b.salesQuantity - a.salesQuantity);
        combinedData = combinedData.slice(0, MAX_RECORDS);

        // Extract data for the chart
        let topLabels = combinedData.map((data) => data.label);
        let topSalesQuantities = combinedData.map((data) => data.salesQuantity);

        // Update charts with top 20 data
        setChartQuantityDetails(
          topLabels,
          topSalesQuantities,
          `Top ${MAX_RECORDS} Available Quantities`
        );
        setLoadingMore(false);
        setLoader(false);
      } else {
        const allSalesQuantity = [...salesQuantity, ...salesQuantityData];
        const allLabelsInfo = [...labels, ...labelsData];

        setChartQuantityDetails(allLabelsInfo, allSalesQuantity);
        setLoadingMore(false);
        setLoader(false);
      }
    } else {
      setSaleQuantity(salesQuantityData);
      setLabels(labelsData);
      setDebitReportData(arr);
      setChartQuantityDetails(labelsData, salesQuantityData);
      setLoader(false);
      setLoadingMore(false);
      setLoader(false);
    }
    setLoadingFilter(false);
  }

  const headersPDF = [
    { label: "Product Name", key: "productName" },
    { label: "Available Quantity", key: "availableQty" },
    { label: "Quantity", key: "quantity" },
    { label: "Brand", key: "brand" },
    { label: "Upc Code", key: "upcCode" },
    { label: "SKU", key: "SKU" },
    { label: "Date Time", key: "dateTime" },
  ];

  const headers = [
    { label: "Product Name", key: "productName" },
    { label: "Available Quantity", key: "availableQty" },
    { label: "Quantity", key: "quantity" },
    { label: "Un used Quantity", key: "unUsedQuantity" },
    { label: "Category", key: "category" },
    { label: "Sub Category", key: "subCategory" },
    { label: "Brand", key: "brand" },
    { label: "Upc Code", key: "upcCode" },
    { label: "SKU", key: "SKU" },
    { label: "Measure", key: "measure" },
    { label: "Minimum Stock", key: "minimumStock" },
    { label: "Maximum Stock", key: "maximumStock" },
    { label: "Reorder Point", key: "reorderPoint" },
    { label: "Lead Time", key: "leadTime" },
    { label: "Date Time", key: "dateTime" },
  ];

  // Static data for re-order report
  const LOW_INVENTORY_ITEMS = [
    "STOPACH",
    "SN-5",
    "Product Sample",
    "STOPACH",
    "SN-5",
    "Product Sample",
    "STOPACH",
    "SN-5",
    "Product Sample",
    "STOPACH",
    "SN-5",
    "Product Sample",
  ];

  const ActionTemplate = ({ rowData }) => {
    return (
      <div className="d-flex justify-content-around w-100">
        <button
          type="button"
          title="View History"
          className="actions-btn"
          onClick={() =>
            window.open(
              `/admin/inventory-history?productName=${encodeURIComponent(
                rowData?.productName
              )}&productID=${encodeURIComponent(rowData?.productId)}`,
              "_blank" // This opens the URL in a new tab
            )
          }
        >
          <VscHistory className="table-action-icon" />
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="content-dashboard-report">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <h4 className="title">Reports / Inventory</h4>
          <div className="filter-wrapper">
            <FilterButton
              getReport={getReport}
              setLoadingFilter={setLoadingFilter}
            />
          </div>
        </div>

        <Row className="chart-row">
          <Col sm="12" md="12" lg="4">
            <Card className="h-100">
              <CardBody>
                <div className="selling-header">
                  <h5>Reorder Stock Report</h5>
                  <BsExclamation size={40} className="exclamation-svg" />
                </div>
                <div className="inventory-list-container">
                  <div className="low-inventory-list">
                    <p>Items with Low Inventory</p>
                    <div className="low-item-list">
                      {LOW_INVENTORY_ITEMS.splice(0, 16).map(
                        (item, index) => (
                          <div
                            key={index}
                            className="w-100 d-flex align-items-center"
                            style={{ gap: "10px" }}
                          >
                            <AiOutlineExclamationCircle size={20} /> {item}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="12" lg="8">
            <Card className="chart-container h-100">
              <CardBody>
                {loadingMore || loadingFilter ? (
                  <ChildLoader />
                ) : (
                  <>
                    <div className="selling-header">
                      <h5>High on Inventory Report</h5>
                      <FaRegCircleCheck
                        size={20}
                        className="check-circle-svg"
                      />
                    </div>
                    <div className="bar-chart-wrapper">
                      <div className="bar-chart-container">
                        <Bar
                          data={chartData}
                          options={options}
                        // height={320}
                        // width={820}
                        />
                      </div>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md="12">
            <Card className="report-table-card">
              <CardBody>
                <div className="report-table-title">
                  <h5>Inventory Report</h5>
                  <DownloadReport
                    reportData={debitReportData}
                    headers={headers}
                    headersPDF={headersPDF}
                  />
                </div>
                {loadingFilter ? (
                  <ChildLoader />
                ) : (
                  <>
                    <DataTable
                      value={debitReportData}
                      tableStyle={{ minWidth: "25rem" }}
                      size="large"
                      resizableColumns
                      columnResizeMode="expand"
                      paginator
                      rowsPerPageOptions={[5, 10, 15]}
                      rows={5}
                      pt={{
                        root: { className: "table" },
                      }}
                    >
                      <Column
                        header="View History"
                        align="center"
                        body={(rowData) => (
                          <ActionTemplate rowData={rowData} />
                        )}
                        exportable={false}
                      />
                      <Column
                        header="Product ID"
                        field="productId"
                        align="center"
                      ></Column>
                      <Column
                        header="Product Name"
                        field="productName"
                      ></Column>
                      <Column header="Category" field="category"></Column>
                      <Column header="Brand" field="brand"></Column>
                      <Column
                        header="Available Quantity"
                        field="availableQuantity"
                        align="center"
                      ></Column>
                      <Column
                        header="Sold Quantity"
                        field="soldQuantity"
                        align="center"
                      ></Column>
                      {/* <Column header="Quantity" field="quantity"></Column> */}
                      <Column
                        header="Reorder Status"
                        field="reorderStatus"
                        align="center"
                      ></Column>
                      <Column
                        header="Reorder Quantity"
                        field="reorderQuantity"
                        align="center"
                      ></Column>
                    </DataTable>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default InventoryReport;

import { useEffect, useRef, useState } from "react";
import "../../assets/styles/MultiStepRegister.css";
import { useNotificationStore } from "hooks/Notification";
import { useLoginStore } from "hooks/Login";
import { useNavigate } from "react-router-dom";
import Common from "helpers/Common";
import { isEmpty } from "underscore";
import success from "assets/img/icons/Auth/success.gif";
import { Spinner } from "reactstrap";

const LoginOTP = ({
  length = 6,
  mobileNumber,
  otpVerify,
  setOtpVerify,
  loginValidate,
  setLoginValidate,
  setIsSuccess,
  isSuccess,
  setLoader,
  loader,
  setIsOTPsend,
}) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const [locationInfo, setLocationInfo] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [deviceInfo, setDeviceInfo] = useState("");
  const [OTPCount, setOTPCount] = useState(3);
  const navigate = useNavigate();
  const isLogin = Common.getJwtToken();
  const [{ companyDetail }, {}, { setAlerts, loginWithOTP, loginVerifyOTP }] =
    useLoginStore();
  const [{}, { sendOTPGeneral }, {}] = useNotificationStore();

  const inputRefs = useRef([]);

  useEffect(() => {
    const getLocation = async () => {
      try {
        const location = await Common.fetchGeolocation();
        setLocationInfo(location);
      } catch (error) {
        setLocationInfo("Unknown Location");
      }
    };

    getLocation();
  }, []);

  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await fetch("https://api64.ipify.org?format=json");
        if (!response.ok) {
          throw new Error("Failed to fetch IP address");
        }
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIPAddress();
  }, []); // Empty array ensures useEffect runs only once on component mount

  useEffect(() => {
    const fetchDeviceInfo = () => {
      const browserInfo = Common.getBrowserInfo();
      const osInfo = Common.getOSInfo();
      const deviceString = `${browserInfo} (${osInfo})`;
      setDeviceInfo(deviceString);
    };

    fetchDeviceInfo();
  }, []);

  const getOTP = async (number) => {
    if (number) {
      let param = {
        location: locationInfo,
        ipAddress: ipAddress,
        device: deviceInfo,
        mobileNumber: "+" + number,
      };
      let res = await sendOTPGeneral(param);
      if (res && res?.isOTPSent == false) {
        // setTimeout(() => {
        setAlerts({ type: "danger", message: res?.success });
        // }, 1000);
        //localStorage.clear();
        // window.location.reload();
      }
    }
  };

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    // Allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // Move to the next input if the current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // Optional: Move focus to the first empty field if clicked on a filled one
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };

  const isOtpComplete =
    otp.join("").length === length && otp.indexOf("") === -1;

  const handleSubmit = async (otp) => {
    setLoader(true);
    const otpString = otp.join("");
    // let error = false;
    // let validate = { ...loginValidate };
    // if (loginValidate && isEmpty(loginValidate)) {
    //   error = true;
    //   validate.mobileNumber = "has-danger";
    //   validate.otp = "has-danger";
    // } else {
    //   if (
    //     !loginValidate.mobileNumber ||
    //     loginValidate.mobileNumber === "has-danger"
    //   ) {
    //     error = true;
    //     validate.mobileNumber = "has-danger";
    //   }
    // }

    // setLoginValidate(validate);

    // if (!error) {
    let param = {
      location: locationInfo,
      ipAddress: ipAddress,
      device: deviceInfo,
      mobileNumber: "+" + mobileNumber,
      otp: otpString,
    };
    let res = await loginVerifyOTP(param);
    if (
      res?.meta?.requestStatus === "fulfilled" &&
      Object.keys(res.payload).length === 0
    ) {
      setIsSuccess(false);
      setLoader(false);

      // Ensure that responseException exists and then access exceptionMessage
      const exceptionMessage =
        res?.payload?.responseException?.exceptionMessage ||
        "We cannot find an account with that mobile number.";

      await setAlerts({
        type: "danger",
        message: exceptionMessage, // Use the exceptionMessage from the response or default
      });

      setOtp(new Array(length).fill(""));

      // Decrease OTP count and reload if no attempts left
      setOTPCount((prevCount) => {
        const newCount = prevCount - 1;
        if (newCount === 0) {
          localStorage.clear();
          window.location.reload();
        }
        return newCount;
      });
    }
    if (
      res &&
      res?.payload?.success == "Pending" &&
      res?.payload?.companyLoginResponse == null
    ) {
      setIsSuccess(false);
      setLoader(false);
      await setAlerts({ type: "danger", message: "Please enter valid OTP" });
      setOtp(new Array(length).fill(""));
      setOTPCount((prevCount) => {
        const newCount = prevCount - 1;
        if (newCount === 0) {
          localStorage.clear();
          window.location.reload();
        }
        return newCount;
      });
    }
    // if (res && res == "Max check attempts reached") {
    //   localStorage.clear();
    //   window.location.reload();
    // }
    if (
      res &&
      res?.payload?.success == "approved" &&
      res?.payload?.companyLoginResponse != null
    ) {
      let companyProfile = res?.payload?.companyLoginResponse?.companyProfile;
      localStorage.setItem("companyProfile", JSON.stringify(companyProfile));
      localStorage.setItem(
        "roleId",
        res?.payload?.companyLoginResponse?.role_id
      );
      setLoader(false);
      setIsSuccess(true);
    }
    // }
  };

  return (
    <>
      {isSuccess ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={success} alt="Success" />
          </div>
        </>
      ) : (
        <div className="login-form-container">
          <div className="w-100 text-center">
            <h4 className="mb-0">Verification</h4>
          </div>
          <>
            <p style={{ textAlign: "center" }}>
              Enter OTP sent to <b>+{mobileNumber}</b>
            </p>
            <div className="otpBox">
              {otp.map((value, index) => {
                return (
                  <input
                    key={index}
                    type="text"
                    ref={(input) => (inputRefs.current[index] = input)}
                    value={value}
                    onChange={(e) => handleChange(index, e)}
                    onClick={() => handleClick(index)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    className="otpInput"
                  />
                );
              })}
            </div>
          </>
          <div>
            <div
              style={{
                margin: "20px 0",
              }}
            >
              <button
                className="custom-login-btn"
                onClick={() => {
                  handleSubmit(otp);
                }}
                disabled={!isOtpComplete}
                style={{
                  cursor: !isOtpComplete ? "not-allowed" : "pointer",
                  pointerEvents: !isOtpComplete ? "none" : "auto",
                  opacity: !isOtpComplete ? 0.6 : 1,
                  width: "100%",
                }}
              >
                {loader ? (
                  <Spinner size="sm" color="primary" className="mr-2" />
                ) : (
                  ""
                )}{" "}
                Verify OTP
              </button>
            </div>
            <div
              style={{
                fontWeight: "550",
              }}
              className="text-center text-size-small mt-2"
            >
              {OTPCount} Attempts remaining.
            </div>
            {!otpVerify && (
              <div className="sign-up-text" style={{ textAlign: "center" }}>
                Didn't receive the verification OTP?
                <a
                  style={{
                    color: "#000",
                    cursor: "pointer",
                  }}
                  onClick={() => getOTP(mobileNumber)}
                >
                  {" "}
                  Resend again{" "}
                </a>
              </div>
            )}

            <div className="sign-up-text" style={{ textAlign: "center" }}>
              Back To&nbsp;
              <span
                style={{ color: "#000", cursor: "pointer" }}
                onClick={() => setIsOTPsend(false)}
              >
                Login
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginOTP;

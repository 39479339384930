import classnames from "classnames";
import Common from "helpers/Common";
import {
  ADMIN_URL_ACTIVITY_LOGS,
  ADMIN_URL_COMPANY_PROFILE,
  ADMIN_URL_FEEDBACK,
  AUTH_URL_LOGIN,
  URL_SITEMAP,
} from "helpers/Paths";
import { useLoginStore } from "hooks/Login";
import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaRegBell } from "react-icons/fa6";
import { IoLogOutOutline, IoMenu } from "react-icons/io5";
import { TbMessageCog } from "react-icons/tb";
import { GoGear } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  DropdownToggle,
  Nav,
  Navbar,
  Tooltip,
  UncontrolledDropdown,
} from "reactstrap";
import LoginAPICall from "service/LoginAPICall";
import { logout } from "store/slice/Login";
import store from "store/store";
import { Menubar } from "primereact/menubar";
import { URL_LOGIN } from "helpers/Paths";
import { URL_OTP } from "helpers/Paths";
import { URL_CHANGE_PASSWORD } from "helpers/Paths";
import { URL_INVENTORY_FILE_SUMMARY_DETAIL } from "helpers/Paths";
import { URL_SIGNUP } from "helpers/Paths";
import { URL_SIGNUPEMAIL } from "helpers/Paths";
import { URL_REGISTRATION } from "helpers/Paths";
import { URL_COMPANY_PROFILE_V2 } from "helpers/Paths";
import { URL_RECENT_BILLS } from "helpers/Paths";
import { URL_RECENT_ENVENTORY } from "helpers/Paths";
import { URL_TECHNICAL_ALERT } from "helpers/Paths";
import { URL_SIGNUP_WITH_DOCUMENTS } from "helpers/Paths";
import { AUTH_SIGNUP_DOCUMENT_DETAILS } from "helpers/Paths";
import { ADMIN_URL_COMPANY_PROFILE_V2 } from "helpers/Paths";
import { URL_SUBSCRIPTION } from "helpers/Paths";
import { URL_COMPANY_PROFILE } from "helpers/Paths";
import { URL_PlanDetails } from "helpers/Paths";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import { URL_DEMO_LOGIN } from "helpers/Paths";

function AdminNavbar(props) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [color, setColor] = React.useState("bg-white");
  const location = useLocation();
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState({});
  const toggleTooltip = (type) =>
    setTooltipOpen({ [type]: !tooltipOpen[type] });

  const loginId = Common.getLoginId();
  const [{}, {}, { setAlerts }] = useLoginStore();

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
  });
  React.useEffect(() => {
    if (
      window.outerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }, [location]);
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    }
    //  else {
    //   setColor("navbar-transparent");
    // }
  };
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setSidebarOpen(!sidebarOpen);
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  const toggleCollapse = () => {
    if (!collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
    setCollapseOpen(!collapseOpen);
  };

  const companyProfileRedirect = () => {
    navigate(ADMIN_URL_COMPANY_PROFILE);
  };

  const activityLogRedirect = () => {
    navigate(ADMIN_URL_ACTIVITY_LOGS);
  };

  const onLogoutClick = () => {
    if (localStorage.getItem("recentBillId") !== null) {
      localStorage.removeItem("recentBillId");
    }

    if (localStorage.getItem("isOpenViewBill") !== null) {
      localStorage.removeItem("isOpenViewBill");
    }

    new LoginAPICall().companyLogout({ loginid: Common.getCompanyId() });
    store.dispatch(logout());
    let isDemo = localStorage.getItem("isDemo");
    if (isDemo) {
      navigate("/");
      localStorage.removeItem("isDemo");
    } else {
      navigate(AUTH_URL_LOGIN);
    }
    setAlerts({
      type: "warning",
      message: loginId + " user is logged out.",
    });
  };
  const onFeedBackClick = () => {
    navigate(ADMIN_URL_FEEDBACK);
  };

  const createMenuItems = (routes, navigate, currentRoute) => {
    return routes
      .map((route) => {
        const isActive = currentRoute === route.layout + (route.path || "");
        let isParentActive = false;

        if (route.views) {
          const subItems = route.views.map((subRoute) => {
            const isSubActive =
              currentRoute === subRoute.layout + subRoute.path;
            if (isSubActive) {
              isParentActive = true; // Mark the parent as active if any sub-item is active
            }
            return {
              label: subRoute.name,
              icon: null,
              className: isSubActive ? "active-nav-menu-sub-item" : "", // Highlight the active sub-item
              command: () => navigate(subRoute.layout + subRoute.path),
            };
          });

          return {
            label: route.name,
            icon: null,
            className: isParentActive ? "active-nav-menu-item" : "", // Apply active class if any sub-item is active
            items: subItems,
          };
        }

        // Filter out routes that should not be included in the menu
        const excludedPaths = [
          URL_LOGIN,
          URL_OTP,
          URL_CHANGE_PASSWORD,
          URL_INVENTORY_FILE_SUMMARY_DETAIL,
          URL_SIGNUP,
          URL_SIGNUPEMAIL,
          URL_REGISTRATION,
          URL_COMPANY_PROFILE_V2,
          URL_RECENT_BILLS,
          URL_RECENT_ENVENTORY,
          URL_TECHNICAL_ALERT,
          URL_SIGNUP_WITH_DOCUMENTS,
          AUTH_SIGNUP_DOCUMENT_DETAILS,
          URL_COMPANY_PROFILE,
          URL_SUBSCRIPTION,
          URL_PlanDetails,
          URL_DEMO_LOGIN,
          URL_SITEMAP,
        ];

        if (!excludedPaths.includes(route.path)) {
          return {
            label: route.name,
            icon: null,
            className: isActive ? "active-nav-menu-item" : "",
            command: () => navigate(route.layout + route.path),
          };
        }

        return null;
      })
      .filter(Boolean); // Remove any null values
  };

  const logoSection = () => {
    return (
      // <a
      //   href="https://apsthreeai.ai/"
      //   target="_blank"
      //   className="navbar-menu-brand"
      // >
      <img
        style={{
          height: "30px",
          width: "30px",
          objectFit: "contain",
        }}
        src={`${ERP_CDN_IMAGE_URL}/new-logo.svg`}
        onError={(e) => {
          e.target.onerror = null; // Prevent infinite loop if logo also fails
          e.target.src = logo;
        }}
        alt="APS Three AI"
      />
      // </a>
    );
  };

  const currentRoute = location.pathname;
  const menuItems = createMenuItems(props.routes, navigate, currentRoute);
  const keyframes = `
  @keyframes highlight {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }`;
  return (
    <>
      <Navbar
        className={classnames("navbar-absolute fixed-top", color)}
        expand="lg"
        style={{ height: "40px" }}
      >
        <Container
          fluid
          className="px-0 d-flex justify-content-between align-items-center"
        >
          <div className="navbar-wrapper">
            {/* <div className="navbar-minimize">
              <Button
                className="btn-icon btn-round center-content sidebar-collapse-btn"
                color="default"
                id="minimizeSidebar"
                onClick={props.handleMiniClick}
              >
                <FaChevronRight className="visible-on-sidebar-mini" />
                <FaChevronLeft className="visible-on-sidebar-regular" />
              </Button>
            </div> */}

            <Menubar
              start={logoSection}
              model={menuItems}
              pt={{
                root: {
                  className: "bg-white p-0 nav-menubar",
                },
                label: {
                  style: { color: "#000000" },
                },
                menuitem: {
                  style: {
                    fontFamily: "'DM Sans', sans-serif",
                    boxShadow: "none",
                    outline: "none",
                  },
                },
              }}
            />

            {/* <div
              className={classnames("navbar-toggle", {
                toggled: sidebarOpen,
              })}
            > */}
            {/* <button
                className="navbar-toggler"
                type="button"
                onClick={toggleSidebar}
              > */}
            {/* <IoMenu size={24} onClick={toggleSidebar} /> */}
            {/* </button> */}
            {/* </div> */}
          </div>

          <Nav navbar className="d-flex align-items-center">
            {/* <UncontrolledDropdown className="btn-rotate mt-2 mb-2" nav>
              <DropdownToggle
                aria-haspopup={true}
                color="default"
                data-toggle="dropdown"
                id="navbarHomeLink"
                nav
              >
                <i onClick={() => { navigate(ADMIN_URL_DASHBOARD) }}> < CiHome size={22} /></i>
              </DropdownToggle>
              <Tooltip
                autohide={false}
                flip={true}
                isOpen={tooltipOpen["dashboard"]}
                target="navbarHomeLink"
                toggle={() => toggleTooltip("dashboard")}
                style={{
                  backgroundColor: "#28a745",
                  color: "#ffffff",
                  padding: 0,
                }}
              >
                dashboard
              </Tooltip>
            </UncontrolledDropdown> */}
            {/* <UncontrolledDropdown className="btn-rotate mt-2 mb-2" nav>
              <DropdownToggle
                aria-haspopup={true}
                color="default"
                data-toggle="dropdown"
                id="navbarHelpLink"
                nav
              >
                <i onClick={()=>{navigate(ADMIN_URL_CUSTOMER_HELP)}} >
                  <IoIosHelpCircleOutline size={22}/>
                </i> 
              </DropdownToggle>
              <Tooltip
                autohide={false}
                flip={true}
                isOpen={tooltipOpen["help"]}
                target="navbarHelpLink"
                toggle={() => toggleTooltip("help")}
                style={{
                  backgroundColor: "#28a745",
                  color: "#ffffff",
                  padding: 0,
                }}
              >
                Help
              </Tooltip>
            </UncontrolledDropdown> */}
            <div className="sitemap-btn" style={{ marginTop: "-10px" }}>
              <style>{keyframes}</style>{" "}
              {/* Insert keyframes into a style tag */}
              <button
                onClick={() => navigate("/admin" + URL_SITEMAP)}
                style={{
                  height: "30px",
                  lineHeight: "normal",
                  backgroundColor: "#FFFFFF",
                  color: "rgb(119, 149, 248)",
                  border: "1px solid rgb(119, 149, 248)",
                }}
              >
                Sitemap
              </button>
            </div>

            <UncontrolledDropdown className="btn-rotate mt-2 mb-2" nav>
              <DropdownToggle
                aria-haspopup={true}
                caret
                color="default"
                data-toggle="dropdown"
                id="navbarActivityDropdownMenuLink"
                nav
              >
                <FaRegBell size={18} onClick={activityLogRedirect} />
                {/* <i className="nc-icon nc-bell-55" onClick={activityLogRedirect} /> */}
              </DropdownToggle>
              <Tooltip
                autohide={false}
                flip={true}
                isOpen={tooltipOpen["activity"]}
                target="navbarActivityDropdownMenuLink"
                toggle={() => toggleTooltip("activity")}
                style={{
                  backgroundColor: "#28a745",
                  color: "#ffffff",
                  padding: 0,
                }}
              >
                Activity Logs
              </Tooltip>
            </UncontrolledDropdown>

            {/* <UncontrolledDropdown className="btn-rotate mt-2 mb-2" nav> */}
            {/* <DropdownToggle
                aria-haspopup={true}
                data-toggle="dropdown"
                id="navbarSettingDropdownMenuLink"
                nav
              >
                <GoGear size={18} onClick={companyProfileRedirect} /> */}
            {/* <i
                  className="nc-icon nc-settings-gear-65"
                  onClick={companyProfileRedirect}
                /> */}
            {/* </DropdownToggle> */}
            {/* <Tooltip
                autohide={true}
                flip={true}
                isOpen={tooltipOpen["setting"]}
                target="navbarSettingDropdownMenuLink"
                toggle={() => toggleTooltip("setting")}
                style={{
                  backgroundColor: "#28a745",
                  color: "#ffffff",
                  padding: 0,
                }}
              >
                Company Profile
              </Tooltip> */}
            {/* </UncontrolledDropdown> */}

            <UncontrolledDropdown className="btn-rotate mt-2 mb-2" nav>
              <DropdownToggle
                aria-haspopup={true}
                data-toggle="dropdown"
                id="navbarFeedbackDropdownMenuLink"
                nav
              >
                <TbMessageCog size={18} onClick={onFeedBackClick} />
                {/* <img onClick={onFeedBackClick} src={feedback} alt="feedback" /> */}
              </DropdownToggle>
              <Tooltip
                autohide={true}
                flip={true}
                isOpen={tooltipOpen["feedback"]}
                target="navbarFeedbackDropdownMenuLink"
                toggle={() => toggleTooltip("feedback")}
                style={{
                  backgroundColor: "#28a745",
                  color: "#ffffff",
                  padding: 0,
                }}
              >
                Feedback
              </Tooltip>
            </UncontrolledDropdown>

            {/* {loginId && (
              <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                {loginId}
              </div>
            )} */}

            {/* <UncontrolledDropdown nav className="user">
              <DropdownToggle
                aria-haspopup={true}
                data-toggle="dropdown"
                id="userMenu"
                nav
              >
                <div className="photo mt-2 mb-2">
                  <img
                    onClick={onLogoutClick}
                    src={logoutimg}
                    alt="logoutimg"
                  />
                </div>
              </DropdownToggle>
              <Tooltip
                autohide={true}
                flip={true}
                isOpen={tooltipOpen["logout"]}
                target="userMenu"
                toggle={() => toggleTooltip("logout")}
                style={{
                  backgroundColor: "#28a745",
                  color: "#ffffff",
                  padding: 0,
                }}
              >
                Logout
              </Tooltip>
            </UncontrolledDropdown>  */}
            <Button className="logout-button" onClick={onLogoutClick}>
              <span>Logout&nbsp;</span>
              <IoLogOutOutline style={{ fontSize: "20px" }} />
            </Button>
          </Nav>
          {/* </Collapse> */}
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;

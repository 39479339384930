import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Container,
} from "reactstrap";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import PHONE from "assets/img/landing-page/icons/phone.png";
import GMAIL from "assets/img/landing-page/icons/gmail.png";
import INTERNET from "assets/img/landing-page/icons/internet.png";
import LINKEDIN from "assets/img/landing-page/icons/linkedin.png";
import WHATSAPP from "assets/img/landing-page/icons/whatsApp.png";

const ConnectUs = () => {
  const SOCIAL_MEDIA_LINKS = [
    {
      name: "LinkedIn",
      icon: `${ERP_CDN_IMAGE_URL}/linkedin.png` || LINKEDIN,
      href: "https://www.linkedin.com/in/apsthreeaillp/",
    },
    {
      name: "WhatsApp",
      icon: `${ERP_CDN_IMAGE_URL}/whatsApp.png` || WHATSAPP,
      href: "https://wa.me/+916356561125",
    },
    {
      name: "Website",
      icon: `${ERP_CDN_IMAGE_URL}/internet.png` || INTERNET,
      href: "https://apsthreeai.ai/",
    },
    {
      name: "Phone",
      icon: `${ERP_CDN_IMAGE_URL}/phone.png` || PHONE,
      href: "tel:+916356561125",
    },
    {
      name: "Gmail",
      icon: `${ERP_CDN_IMAGE_URL}/gmail.png` || GMAIL,
      href: "mailto:admin@apsthreeai.ai",
    },
  ];

  return (
    <Container className="content-dashboard-payment">
      <div className="title-route-container">
        <h4 className="title">Help & Activity / Connect Us</h4>
      </div>
      <Row className="justify-content-center">
        {SOCIAL_MEDIA_LINKS.slice(0, 3).map(({ name, icon, href }, index) => (
          <Col key={index} md="4" className="mb-4">
            <a href={href} target="_blank" rel="noopener noreferrer">
              <Card className="contact-card">
                <CardBody className="text-center">
                  <img src={icon} alt={name} className="contact-icon mb-3" />
                  <h5>{name}</h5>
                </CardBody>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
      <Row className="justify-content-center">
        {SOCIAL_MEDIA_LINKS.slice(3).map(({ name, icon, href }, index) => (
          <Col key={index} md="4" className="mb-4">
            <a href={href} target="_blank" rel="noopener noreferrer">
              <Card className="contact-card">
                <CardBody className="text-center">
                  <img src={icon} alt={name} className="contact-icon mb-3" />
                  <h5>{name}</h5>
                </CardBody>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ConnectUs;

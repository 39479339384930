import PageLoader from "components/Loader/PageLoader";
import { saveAs } from "file-saver";
import { URL_MANAGE_CUSTOMER } from "helpers/Paths";
import { useCompanyStore } from "hooks/Company";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import * as XLSX from "xlsx";

const CustomersList = () => {
    const [loader, setLoader] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [{ customerList }, { getCustomerByCompanyId }] = useCompanyStore();
    const [customerData, setCustomerData] = useState([]);

    const ActionTemplate = ({ rowData }) => {
        const navigate = useNavigate();

        const openModifyCustomer = (customerID) => {
            navigate(`/admin${URL_MANAGE_CUSTOMER}`, {
                state: { customerID: customerID },
            });
        };

        return (
            <div className="d-flex justify-content-around w-100">
                <button
                    type="button"
                    className="template-edit-btn"
                    onClick={() => openModifyCustomer(rowData?.customerID)}
                >
                    <FaEdit size={20} />
                </button>
            </div>
        );
    };

    // const loadMoreData = () => {};

    const exportToExcel = () => {
        const worksheetData = [
            [
                "Customer Name",
                "Customer Email",
                "Phone",
                "City",
                "Pincode",
                "State",
            ],
            ...customerData.map((data) => [
                data.customerName,
                data.emailId,
                data.phoneNumber,
                data.city,
                data.pincode,
                data.state,
            ]),
        ];

        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
            workbook,
            worksheet,
            "Inventory Expiry Report"
        );

        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

        const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
        saveAs(data, "customer_list.xlsx");
    };

    const handleFilterInputChange = (value) => {
        setLoader(true);
        if (value) {
            const filteredData = customerList.filter((data) => {
                return (
                    data?.customerName
                        ?.toLowerCase()
                        .includes(value?.toLowerCase()) ||
                    data?.emailId
                        ?.toLowerCase()
                        .includes(value?.toLowerCase()) ||
                    data?.phoneNumber
                        ?.toLowerCase()
                        .includes(value?.toLowerCase())
                );
            });
            setCustomerData(filteredData);
        } else {
            setCustomerData(customerList);
        }
        setLoader(false);
    };

    useEffect(() => {
        async function getCustomerList() {
            await getCustomerByCompanyId();
            setLoader(false);
        }
        getCustomerList();
    }, []);

    useEffect(() => {
        if (customerList) {
            setCustomerData(customerList);
        }
    }, [customerList]);

    return (
        <>
            {loader ? (
                <PageLoader />
            ) : (
                <>
                    <div className="content-dashboard-report">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="title">Customers / List</h4>
                        </div>

                        <Row>
                            <Col md="12">
                                <Card className="report-table-card">
                                    <CardBody>
                                        <Row className="mb-2">
                                            <Col sm="12" md="4" lg="4">
                                                <div className="report-table-title">
                                                    <h5>Customer List</h5>
                                                </div>
                                            </Col>
                                            <Col
                                                sm="12"
                                                md="4"
                                                lg="4"
                                                className="d-flex justify-content-center align-items-center"
                                            >
                                                <div className="customer-search-box">
                                                    <input
                                                        type="text"
                                                        placeholder="Search customer..."
                                                        onChange={(e) =>
                                                            handleFilterInputChange(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <IoMdSearch size={20} />
                                                </div>
                                            </Col>
                                            <Col
                                                sm="12"
                                                md="4"
                                                lg="4"
                                                className="export-btn-wrapper"
                                            >
                                                <div className="export-btn-container">
                                                    <button
                                                        className="export-xls-btn"
                                                        style={{ margin: 0 }}
                                                        onClick={exportToExcel}
                                                    >
                                                        Export XLS{" "}
                                                        <RiFileExcel2Fill
                                                            size={20}
                                                        />
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>

                                        <DataTable
                                            value={customerData ?? []}
                                            tableStyle={{ minWidth: "25rem" }}
                                            pt={{
                                                root: { className: "table" },
                                            }}
                                        >
                                            <Column
                                                field="customerName"
                                                header="Customer Name"
                                            ></Column>
                                            <Column
                                                field="emailId"
                                                header="Customer Email"
                                            ></Column>
                                            <Column
                                                field="phoneNumber"
                                                header="Phone"
                                            ></Column>
                                            <Column
                                                field="city"
                                                header="City"
                                            ></Column>
                                            <Column
                                                field="pincode"
                                                header="Pincode"
                                            ></Column>
                                            <Column
                                                field="state"
                                                header="State"
                                            ></Column>
                                            <Column
                                                className="text-center"
                                                header="Edit"
                                                body={(rowData) => (
                                                    <ActionTemplate
                                                        rowData={rowData}
                                                    />
                                                )}
                                                exportable={false}
                                                align="center"
                                            />
                                        </DataTable>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col className="text-center">
                                {loadingMore ? (
                                    <ChildLoader />
                                ) : (
                                    <LoadMore
                                        totalData={SAMAPLE_DATA.length}
                                        handleLoadMore={() => loadMoreData()}
                                    />
                                )}
                            </Col>
                        </Row> */}
                    </div>
                </>
            )}
        </>
    );
};

export default CustomersList;

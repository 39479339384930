import ComingSoon from "components/ComingSoon/ComingSoon";
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Container,
  Input,
  Button,
} from "reactstrap";
const RaiseIssue = () => {
  return (
    <>
      <div className="content-dashboard-payment">
        <div className="title-route-container">
          <h4 className="title">Help & Activity / Raise Issue</h4>
        </div>

        <Card className="payment-data-card">
          <CardBody>
            <ComingSoon></ComingSoon>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default RaiseIssue;

import { useLoginStore } from "hooks/Login";
import { useNotificationStore } from "hooks/Notification";
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  Col,
  Row,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Button 
} from "reactstrap";
import { TabView, TabPanel } from "primereact/tabview";
import { isEmpty } from "underscore";
import { useCompanyStore } from "hooks/Company";
import NotificationEmailSchedule from "../Notification/NotificationEmailSchedule";
import NotificationWPSchedule from "../Notification/NotificationWPSchedule";
import NotificationSMSSchedule from "../Notification/NotificationSMSSchedule";
import NotificationScheduleList from "../Notification/NotificationScheduleList";
import "index.css";
import Common from "helpers/Common";
import AddCustomerModal from "views/CreateBill/AddCustomerModal";
import EditCustomerModal from "views/CreateBill/EditCustomerModal";
import AddCustomer from "./AddCustomer";
import EditCustomer from "./EditCustomer";
import { useLocation } from "react-router-dom";

const AddModifyCustomer = () => {
  const [activeIndexTemp, setActiveIndexTemp] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [customerID, setCustomerID] = useState(null);
  const location = useLocation();

  const handleTabChange = (e) => {
    if (formChanged) {
      if (activeIndex !== e.index) {
        setActiveIndexTemp(e.index);
        setConfirmModal(true);
      }
    } else {
      setActiveIndex(e.index);
    }
  };

  const handleConfirm = () => {
    setActiveIndex(activeIndexTemp);
    setConfirmModal(false);
    setFormChanged(false); // Reset form changed state after confirming
  };

  const toggle = () => {
    setConfirmModal(false);
    if (formChanged === true) {
      setFormChanged(true);
    } else {
      setFormChanged(false);
    }
  };

  useEffect(() => {
    if (location.state && location.state.customerID) {
      setActiveIndex(1);
      setCustomerID(location.state.customerID);
    }
  },[location.pathname]);

  return (
    <div className="content-dashboard-notification">
      <h4 className="title">Manage Customers</h4>
      <Card
        body
        color="secondary"
        outline
        style={{
          width: "100%",
        }}
      >
        <Row xs="2">
          <Col className="" xs="12" md="12" lg="12">
            <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
              <TabPanel header="Add Customer">
                <AddCustomer setFormChanged={setFormChanged} />
              </TabPanel>
              <TabPanel header="Modify Customer">
                <EditCustomer setFormChanged={setFormChanged} customerID={customerID}  />
              </TabPanel>
            </TabView>
          </Col>
          <Modal
            isOpen={confirmModal}
            toggle={toggle}
            backdrop="static"
            centered={true}
          >
            <ModalBody>
              All data if entered will be erased. Do you want to continue?
            </ModalBody>
            <ModalFooter style={{ justifyContent: "center" }}>
              <Button color="primary" onClick={handleConfirm}>
                Continue
              </Button>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </Card>
    </div>
  );
};

export default AddModifyCustomer;
